import {ArrowCircleUp} from "@mui/icons-material";
import {useState} from "react";

export const ScrollToTop = (props) => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        } else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);


    return (
        <ArrowCircleUp onClick={scrollToTop} sx={{
            display: visible ? 'flex' : 'none',
            flex: 1,
            justifySelf: 'flex-start',
            justifyItems: 'flex-start',
            position: 'fixed',
            width: '100%',
            left: {md: '45%', xs: '40%'},
            bottom: '40px',
            zIndex: '1',
            cursor: 'pointer',
            fontSize: {md: '100px', xs: '50px'},
        }} fontSize={'large'} color={'primary'}/>
    );
}

