import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    Link,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import background from './../assets/images/background.png';
import pot1 from './../assets/images/pot-1.png';
import pot2 from './../assets/images/pot-2.png';
import doctorIllustration from './../assets/images/doctor-illustration.png';
import macPro16 from './../assets/vectors/macPro.svg';
import iphone from "../assets/vectors/iPhoneMock.svg";
import searchIcon from "../assets/vectors/Search-Icon.svg";
import starIcon from "../assets/vectors/Star-Icon.svg";
import arrowLeft from "../assets/vectors/Arrow-Left.svg";
import arrowDown from "../assets/vectors/Arrow-Down-Box.svg";
import checkIcon from "../assets/vectors/CheckIcon.svg";
import * as emailjs from "@emailjs/browser";
import {useSnackbar} from "notistack";
import {useState} from "react";
import {ScrollToTop} from "../components/common/scrollToTopButton";

export const Home = () => {
    const theme = useTheme();
    const styles = themeOverrides(theme);
    const [userType, setUserType] = useState('patient')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [emailError, setEmailError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const handleOptionChange = (event) => {
        setUserType(event.target.value);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (name === '') {
            setNameError(true);
            return;
        } else {
            setNameError(false);
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setEmailError(true);
            return
        } else {
            setEmailError(false);
        }

        emailjs.send('service_2k6boza', 'template_fg8p24r', {userType, name, email, message}, 'Hq3HmnxlcY-25-R4p')
            .then((result) => {
                enqueueSnackbar("Information submitted successfully", {variant: "success"});
                console.log('Email sent successfully', result.text);
            }, (error) => {
                enqueueSnackbar("Information submission failed", {variant: "error"});
                console.log('Error sending email', error.text);
            });
    };


    const renderShape1 = () => {
        return (
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Box sx={{
                    borderRadius: '20px',
                    border: '2px solid',
                    backgroundColor: 'primary.main',
                    borderColor: 'primary.main',
                    width: 39.72,
                    height: 5,
                    mr: theme.spacing(0.41625)
                }}
                />
                <Box sx={{
                    borderRadius: '20px',
                    border: '2px solid',
                    backgroundColor: 'primary.main',
                    borderColor: 'primary.main',
                    width: 6.11,
                    height: 5,
                    mr: theme.spacing(0.41625)
                }}
                />
                <Box sx={{
                    borderRadius: '20px',
                    border: '2px solid',
                    backgroundColor: 'primary.main',
                    borderColor: 'primary.main',
                    width: 6.11,
                    height: 5,

                }}
                />

            </Box>
        )
    }
    const renderShape2 = () => {
        return (
            <Box
                sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <Box
                    sx={{
                        borderRadius: '20px',
                        border: '2px solid',
                        backgroundColor: 'primary.main',
                        borderColor: 'primary.main',
                        width: 6.11,
                        height: 5,
                        mr: theme.spacing(0.41625)
                    }}
                />
                <Box
                    sx={{
                        borderRadius: '20px',
                        border: '2px solid',
                        backgroundColor: 'primary.main',
                        borderColor: 'primary.main',
                        width: 6.11,
                        height: 5,
                        mr: theme.spacing(0.41625)

                    }}
                />
                <Box
                    sx={{
                        borderRadius: '20px',
                        border: '2px solid',
                        backgroundColor: 'primary.main',
                        borderColor: 'primary.main',
                        width: 39.72,
                        height: 5,
                        mr: theme.spacing(0.41625)
                    }}
                />
            </Box>)
    }
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: {md: theme.spacing(21.75), xs: theme.spacing(13.75)}
        }}>

            <Box sx={styles.mainWrapper}>
                <Typography sx={styles.mainHeading}>
                    <span style={{color: '#499DD2'}}>We’re the Future{' '}</span>
                    of Asthma management.
                </Typography>
                <Box sx={{pt: theme.spacing(4.225)}}>
                    <Typography
                        sx={styles.subHeadings}>
                        Make AYRA your digital partner in optimizing asthma care.
                        Empower your patients to self-manage their asthma using interactive action plans.
                        Encourage correct inhaler technique, adherence and asthma control in patients.
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Box component="img"
                         sx={{
                             width: '100px',
                             height: '200px',
                             display: {md: 'flex', xs: 'none'}
                         }}
                         src={pot1}
                         alt={'bg'}
                    />
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>


                        <Button sx={{
                            ...styles.navigationButton,
                            borderRadius: '10px',
                            border: '3px solid #499DD2',
                            py: theme.spacing(2),
                            px: theme.spacing(5),
                            mt: theme.spacing(4),
                            display: {md: 'flex', xs: 'flex', sm: 'flex', lg: 'none', xl: 'none'}
                        }}
                                onClick={() => {
                                    document.getElementById('request-demo').scrollIntoView({behavior: 'smooth'})
                                }}
                                color={'primary'}>
                            <Link underline={'none'} onClick={() => {
                                // navigate('/privacy-policy')
                            }} sx={{
                                fontFamily: 'Poppins',
                                fontWeight: '700',
                                fontSize: '16px',
                                lineHeight: {md: '24px', xs: '12px'},
                                textTransform: 'uppercase',
                                color: 'primary',

                            }}>
                                Request Demo
                            </Link>
                        </Button>
                        <Box sx={{mt: theme.spacing(4), flex: 1, display: 'flex', justifyContent: 'center'}}>
                            <Button
                                onClick={() => {
                                    document.getElementById('provider').scrollIntoView({behavior: 'smooth'})
                                }}
                                variant={"contained"}
                                sx={[styles.topButtons, {marginRight: theme.spacing(4)}]}>
                                <Typography sx={styles.topButtonsText}>
                                    FOR PROVIDER
                                </Typography>

                            </Button>
                            <Button
                                onClick={() => {
                                    document.getElementById('patient').scrollIntoView({behavior: 'smooth'})
                                }}
                                variant={"contained"}
                                sx={styles.topButtons}>
                                <Typography sx={styles.topButtonsText}>
                                    FOR PATIENT
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                    <Box component="img"
                         sx={{
                             width: '100px',
                             height: '200px',
                             display: {md: 'flex', xs: 'none'}
                         }}
                         src={pot2}
                         alt={'bg'}
                    />
                </Box>
            </Box>

            <Grid container sx={{
                mt: {md: theme.spacing(11.5), xs: theme.spacing(8)},
                px: {md: theme.spacing(19), xs: theme.spacing(5.3)},
            }}>
                <Grid item xs={12} sm={6} sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Box component={'img'} src={doctorIllustration} sx={{
                        width: '326.71px',
                        height: '317.9px',
                        // alignSelf: 'center'
                    }}/>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{flex: 1}}>
                    <Box>
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '700',
                            fontSize: {md: '24px', xs: '16px'},
                            lineHeight: {md: '36px', xs: '24px'},
                            mt: theme.spacing(5),
                            mb: theme.spacing(1.1)
                        }}>
                            WHO WE ARE?
                        </Typography>
                        {renderShape1()}
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            fontSize: {md: '20px', xs: '14px'},
                            lineHeight: {md: '30px', xs: '21px'},
                            mt: {md: theme.spacing(5.75), xs: theme.spacing(2)}
                        }}>
                            We have built AYRA for patients, providers and health care systems
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', mt: theme.spacing(1.6)}}>

                        {
                            ["Prevent and reduce emergency health care visits", "Provide uptodate asthma treatment care", "Cost-effective utilization of hospital resources"].map(title => (
                                <Box key={`view-${title}`}
                                     sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                                    <Box component={'img'} src={checkIcon} sx={{alignSelf: 'center'}}/>
                                    <Typography sx={{
                                        alignSelf: 'center',
                                        fontFamily: 'Poppins',
                                        fontSize: {md: '20px', xs: '14px'},
                                        lineHeight: {md: '35px', xs: '20px'},
                                        ml: {md: theme.spacing(3.75), xs: theme.spacing(1.9)},
                                        mb: {md: 0, xs: theme.spacing(2)}
                                    }}>
                                        {title}
                                    </Typography>
                                </Box>
                            ))
                        }

                    </Box>
                </Grid>
            </Grid>
            <Box id={'provider'}/>
            <Box sx={{
                mt: {md: theme.spacing(17.3), xs: theme.spacing(11.375)},
                px: {md: theme.spacing(13.75), xs: theme.spacing(5.375)},
                width: '100%'
            }}>
                <Box>
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontWeight: '700',
                        fontSize: {md: '40px', xs: '20px'},
                        lineHeight: {md: '60px', xs: '30px'},
                        marginBottom: theme.spacing(1.625)
                    }}>
                        Are you a Health Care Provider?
                    </Typography>
                    {renderShape1()}
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontSize: {md: '20px', xs: '14px'},
                        lineHeight: {md: '30px', xs: '21px'},
                        marginTop: {md: theme.spacing(4.125), xs: theme.spacing(2.2)}
                    }}>
                        AYRA enables you to manage your patients like never before. Guide your patients in staying one
                        step ahead of asthma symptoms.
                    </Typography>
                </Box>
            </Box>


            <Grid container sx={{
                display: 'flex',
                mt: theme.spacing(10),
                px: {md: theme.spacing(13.75), xs: theme.spacing(5.25)}
            }}>


                {
                    [
                        {
                            title: 'Automated asthma action plans',
                            description: 'Generate customized asthma action plans in a single click',
                            iconSrc: arrowDown
                        },
                        {
                            title: 'Effectively utilize clinic time',
                            description: 'Quick and easy digital interactive asthma action plans. Inhaler technique videos and tailored educational content',
                            iconSrc: starIcon
                        },
                        {
                            title: 'Objective assessment of your patient',
                            description: ' Utilizing electronic peak flow and symptom diaries. Identify at-risk patients at a glance through patient dashboard with their color-coded symptoms tracker.',
                            iconSrc: searchIcon
                        }
                    ].map((section, index) => (
                        <Grid item xs={12} sm={4} key={index}
                              sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: {md: 'center', xs: 'flex-start'},
                                  px: {md: theme.spacing(11), xs: theme.spacing(0)}
                              }}>
                            <Box sx={{
                                borderRadius: '50%',
                                border: '2px solid',
                                borderColor: 'primary.main',
                                width: 59.21,
                                height: 59.21,
                                display: 'flex',
                                alignItems: 'center', justifyContent: 'center'
                            }}
                            > <Box component={'img'} src={section.iconSrc}/></Box>
                            <Typography sx={{
                                fontFamily: 'Poppins',
                                fontWeight: '600',
                                fontSize: {md: '24px', xs: '16px'},
                                lineHeight: {md: '36px', xs: '24px'},
                                textAlign: {md: 'center', xs: 'left'},
                                justifyContent: 'center',
                                mt: {md: '21.79px', xs: '4.5px'},
                                px: {md: theme.spacing(3), xs: 0}
                            }}>
                                {section.title}
                            </Typography>
                            <Typography sx={{
                                fontFamily: 'Poppins',
                                fontWeight: '400',
                                fontSize: {md: '18px', xs: '14px'},
                                lineHeight: {md: '27px', xs: '21px'},
                                textAlign: {md: 'center', xs: 'left'},
                                justifyContent: 'center',
                                mt: {md: '39px', xs: '6px'},
                                mb: {md: 0, xs: theme.spacing(4.25)}
                            }}>
                                {section.description}
                            </Typography>
                        </Grid>
                    ))
                }
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    position: 'absolute',
                    left: -600,
                    marginTop: theme.spacing(45)
                }}>
                    <Box sx={{
                        width: '843px',
                        height: '843px',
                        alignSelf: 'center',
                        backgroundColor: '#EDF6FF',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: -0,
                        zIndex: -1
                    }}/>
                    <Box sx={{
                        width: '932px',
                        height: '932px',
                        backgroundColor: '#EDF6FF80',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: -44,
                        zIndex: -2
                    }}/>
                    <Box sx={{
                        width: '1032px',
                        height: '1032px',
                        backgroundColor: '#EDF6FF1A',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: -94,
                        zIndex: -3
                    }}/>
                </Box>
            </Grid>


            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mt: '76.2px',
                width: '100%'
            }}>
                <Box component="img"
                     sx={{
                         // mx: theme.spacing(25),
                         width: {md: '70%', xs: '90%'}
                     }}
                     src={macPro16}
                     alt={'bg'}
                />
                <Box mt={'67.66px'}>
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            window.open('https://portal.ayra.health/doctor/', '_blank');
                        }}
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'primary.mainBg',
                            borderRadius: '10px',
                            marginLeft: {md: '32px', xs: 0},
                            px: {md: '35.5px', xs: theme.spacing(6)},
                            py: '15px'
                        }}>
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '700',
                            fontSize: {md: '18px', xs: '14px'},
                            lineHeight: {md: '27px', xs: '21px'},
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}>
                            GET STARTED AS A PROVIDER
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <Box id={"patient"} sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                pt: '140px',
                mx: {md: theme.spacing(13.75), xs: theme.spacing(5.375)}
            }}>

                <Typography sx={{
                    fontFamily: 'Poppins',
                    fontWeight: '700',
                    fontSize: {md: '40px', xs: '20px'},
                    lineHeight: {md: '60px', xs: '30px'},
                    justifyContent: 'center',
                    textAlign: 'right'
                }}>
                    Are you a Patient?
                </Typography>
                <Box sx={{pt: '11px'}}>
                    {renderShape2()}
                </Box>
                <Typography sx={{
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                    fontSize: {md: '20px', xs: '14px'},
                    lineHeight: {md: '30px', xs: '21px'},
                    pt: '24px',
                    textAlign: 'right',
                }}>
                    AYRA, the world's first interactive asthma self-management app, designed to help you achieve the
                    best asthma control possible. AYRA, created by asthma experts and patients, putting you in control
                    like never before. Suitable for users 18 years or above.
                </Typography>
            </Box>
            <Grid container sx={{
                display: 'flex',
                flexDirection: {md: 'row', sm: 'row-reverse', xs: 'row-reverse'},
                alignItems: 'center',
                justifyContent: 'center',
                pt: '51px',
                px: {md: theme.spacing(13.75), xs: theme.spacing(5.375)}
            }}>
                <Grid item xs={12} sm={6} md={7} sx={{
                    flex: 1,
                    display: {sm: 'flex', md: 'none', xs: 'flex'},
                    flexDirection: 'column',
                    justifyContent: 'center',
                    pt: theme.spacing(5)
                    // pl: '149px'
                }}>

                    {
                        [
                            {
                                title: 'Take Control',
                                description: 'Control your asthma better with your interactive personalized asthma action plan.',
                                iconSrc: arrowDown
                            },
                            {
                                title: 'Become Aware',
                                description: 'Gauge your asthma control through peak flow readings and symptoms diaries. Never forget to take your medication through regular reminders.',
                                iconSrc: starIcon
                            },
                            {
                                title: 'Become Educated',
                                description: 'Improve your knowledge of asthma for better control through our educational content and inhaler videos',
                                iconSrc: searchIcon
                            }
                        ].map((section, index) => (
                            <Box key={index} sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: {md: 'center', xs: 'flex-start'},
                                px: {md: theme.spacing(11), xs: theme.spacing(0)}
                            }}>
                                <Box sx={{
                                    borderRadius: '50%',
                                    border: '2px solid',
                                    borderColor: 'primary.main',
                                    width: 59.21,
                                    height: 59.21,
                                    display: 'flex',
                                    alignItems: 'center', justifyContent: 'center'
                                }}
                                > <Box component={'img'} src={section.iconSrc}/>
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                }}>
                                    <Typography sx={{
                                        fontFamily: 'Poppins',
                                        fontWeight: '600',
                                        fontSize: {md: '24px', xs: '16px'},
                                        lineHeight: {md: '36px', xs: '24px'},
                                        textAlign: {md: 'center', xs: 'left'},
                                        justifyContent: 'center',
                                        mt: {md: '21.79px', xs: '4.5px'},
                                        px: {md: theme.spacing(3), xs: 0}
                                    }}>
                                        {section.title}
                                    </Typography>
                                    <Typography sx={{
                                        fontFamily: 'Poppins',
                                        fontWeight: '400',
                                        fontSize: {md: '18px', xs: '14px'},
                                        lineHeight: {md: '27px', xs: '21px'},
                                        textAlign: {md: 'center', xs: 'left'},
                                        justifyContent: 'center',
                                        mt: {md: '39px', xs: '6px'},
                                        mb: {md: 0, xs: theme.spacing(4.25)}
                                    }}>
                                        {section.description}
                                    </Typography>
                                </Box>
                            </Box>
                        ))
                    }
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        backgroundColor: 'primary.lightblue',
                        fontWeight: '700',
                        fontSize: '24px',
                        lineHeight: '36px',
                        color: 'primary.black',
                        borderRadius: '10px',
                        mt: theme.spacing(11.125),
                        px: theme.spacing(4.625),
                        py: theme.spacing(2.5),
                        display: {md: 'block', sm: 'none', xs: 'none'},
                        textAlign: 'center',
                    }}>
                        Talk to your physician today about AYRA
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={5} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    // alignItems: 'center'
                }}>
                    <Box component="img"
                         sx={{
                             width: {md: '50%', xs: '160%'},
                             alignSelf: 'center'
                         }}
                         src={iphone}
                         alt={'bg'}/>
                    <Box sx={{
                        width: '583px',
                        height: '583px',
                        backgroundColor: '#F8FCFF',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: -200,
                        zIndex: -1
                    }}/>

                </Grid>
                <Grid item xs={12} sm={6} md={7} sx={{
                    flex: 1,
                    display: {md: 'flex', sm: 'none', xs: 'none'},
                    flexDirection: 'column',
                    justifyContent: 'center',
                    pt: theme.spacing(5)
                    // pl: '149px'
                }}>

                    {
                        [
                            {
                                title: 'Take Charge',
                                description: 'Control your asthma better with your interactive personalized asthma action plan.',
                                iconSrc: arrowDown
                            },
                            {
                                title: 'Become Aware',
                                description: 'Gauge your asthma control through peak flow readings and symptoms diaries. Never forget to take your medication through regular reminders.',
                                iconSrc: starIcon
                            },
                            {
                                title: 'Become Educated',
                                description: 'Improve your knowledge of asthma for better control through our educational content and inhaler videos',
                                iconSrc: searchIcon
                            }
                        ].map((section, index) => (
                            <Box key={index} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                pt: '48px'
                            }}>
                                <Box sx={{

                                    borderRadius: '50%',
                                    border: '2px solid',
                                    borderColor: 'primary.main',
                                    width: 59.21,
                                    height: 59.21,
                                    display: 'flex',
                                    alignItems: 'center', justifyContent: 'center'
                                }}
                                > <Box component={'img'} src={section.iconSrc}/>
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    pl: '48.79px'
                                }}>
                                    <Typography sx={{
                                        fontFamily: 'Poppins',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        lineHeight: '36px',
                                    }}>
                                        {section.title}
                                    </Typography>
                                    <Typography sx={{
                                        fontFamily: 'Poppins',
                                        fontWeight: '400',
                                        fontSize: '18px',
                                        lineHeight: '27px'
                                    }}>
                                        {section.description}
                                    </Typography>
                                </Box>
                            </Box>
                        ))
                    }
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        backgroundColor: 'primary.lightblue',
                        fontWeight: '700',
                        fontSize: '24px',
                        lineHeight: '36px',
                        color: 'primary.black',
                        borderRadius: '10px',
                        mt: theme.spacing(11.125),
                        px: theme.spacing(4.625),
                        py: theme.spacing(2.5),

                        textAlign: 'center',
                    }}>
                        Talk to your physician today about AYRA
                    </Typography>

                    {/*<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', right: 350, marginTop: theme.spacing(45)}}>*/}
                    {/*    <Box sx={{position: 'relative', right: 300, zIndex: -1, width: '100%'}}>*/}
                    {/*        <Box sx={{width: '843px', height: '843px', alignSelf: 'center', backgroundColor: '#EDF6FF', borderRadius: '50%', position: 'absolute', left: -0, zIndex: -1}}/>*/}
                    {/*        <Box sx={{width: '932px', height: '932px', backgroundColor: '#EDF6FF80', borderRadius: '50%', position: 'absolute', left: -44, zIndex: -2}}/>*/}
                    {/*        <Box sx={{width: '1032px', height: '1032px', backgroundColor: '#EDF6FF1A', borderRadius: '50%', position: 'absolute', left: -94, zIndex: -3}}/>*/}
                    {/*    </Box>*/}

                    {/*</Box>*/}

                </Grid>
                <Typography sx={{
                    fontFamily: 'Poppins',
                    backgroundColor: 'primary.lightblue',
                    fontWeight: '700',
                    fontSize: {md: '24px', xs: '16px'},
                    lineHeight: {md: '36px', xs: '24px'},
                    color: 'primary.black',
                    borderRadius: '10px',
                    mt: theme.spacing(11.125),
                    px: {md: theme.spacing(4.625), xs: theme.spacing(1)},
                    py: theme.spacing(2.5),
                    display: {sm: 'block', xs: 'block', md: 'none'},
                    textAlign: 'center',
                }}>
                    Talk to your physician today about AYRA
                </Typography>
            </Grid>


            {/*<Grid item xs={12} sm={6} md={4}*/}
            {/*      sx={{display: 'flex', justifyContent: 'flex-end', pt: '70px', pr: '140px'}}>*/}
            {/*    <Button*/}
            {/*        sx={{*/}
            {/*            backgroundColor: 'primary.lightblue',*/}
            {/*            color: 'primary.mainBg',*/}
            {/*            borderRadius: '10px',*/}
            {/*            marginLeft: '32px',*/}
            {/*            px: '35.5px',*/}
            {/*            py: '15px'*/}
            {/*        }}>*/}
            {/*        */}
            {/*    </Button>*/}
            {/*</Grid>*/}

            <Box id={"request-demo"}/>
            <Grid container sx={{
                flexDirection: 'row', flex: 1, display: 'flex', mt: {md: '223px', xs: '115px'}, px: {
                    md: theme.spacing(13.75), xs: theme.spacing(2)
                }
            }}>
                <Grid item xs={12} sm={6} md={6} sx={{
                    flex: 1,
                    display: {md: 'none', sm: 'flex', xs: 'flex'},
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontWeight: '700',
                        fontSize: {md: '32px', xs: '20px'},
                        lineHeight: {md: '48px', xs: '30px'},
                        textAlign: 'center'
                    }}>
                        Ask for free demo
                    </Typography>
                    <Box sx={{mt: '27px'}}>
                        {renderShape1()}
                    </Box>

                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontWeight: '400',
                        fontSize: {md: '20px', xs: '14px'},
                        lineHeight: {md: '30px', xs: '21px'},
                        pt: '37px',
                        textAlign: 'center',
                        mb: theme.spacing(7)
                    }}>
                        Our support team will contact you within 24 hours to understand your requirement
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    // alignItems: 'center',
                    borderRadius: '20px',
                    backgroundColor: '#F0F8FF',
                    px: {md: theme.spacing(2.5), xs: theme.spacing(1.5)}
                }}>


                    <FormControl sx={{}}>
                        <FormLabel sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                            fontSize: '20px',
                            lineHeight: '24.38px',
                            textAlign: 'center',
                            color: '#000000',
                            pt: '37px'
                        }}
                                   id="demo-radio-buttons-group-label">who are you?</FormLabel>
                        <RadioGroup
                            sx={{flexDirection: 'row', display: 'flex', justifyContent: 'center', pt: '37px'}}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={userType}
                            onChange={handleOptionChange}
                        >
                            <Grid container sx={{px: {md: theme.spacing(16.5), xs: theme.spacing(7)}}}>
                                <Grid item md={6} sm={6} xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <FormControlLabel
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontWeight: '500',
                                            fontSize: '15px',
                                            lineHeight: '18.29px',
                                            textAlign: 'center',
                                            color: '#000000',
                                            mr: theme.spacing(12.5)
                                        }}
                                        value="patient"
                                        control={<Radio/>}
                                        label="Patient"/>
                                </Grid>
                                <Grid item md={6} sm={6} xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <FormControlLabel
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontWeight: '500',
                                            fontSize: '15px',
                                            lineHeight: '18.29px',
                                            color: '#000000',
                                            textAlign: 'center'
                                        }}
                                        value="provider"
                                        control={<Radio/>}
                                        label="Provider"/>
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{pt: '37px'}} variant={'standard'}>
                        <FormLabel
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: '500',
                                fontSize: '20px',
                                color: '#000000',
                                lineHeight: '24.38px'
                            }}
                        >Name *</FormLabel>
                        <Input
                            sx={{
                                backgroundColor: 'primary.mainBg',
                                borderRadius: '10px',
                                px: theme.spacing(2.5),
                                py: theme.spacing(2)
                            }}
                            id="input-name"
                            variant={'standard'}
                            aria-describedby="input-name-helper-text"
                            disableUnderline
                            placeholder={'e.g Sam'}
                            value={name}
                            error={nameError}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {nameError && <div style={{color: 'red'}}>Name required</div>}
                    </FormControl>
                    <FormControl sx={{pt: '19px'}}>
                        <FormLabel
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: '500',
                                fontSize: '20px',
                                color: '#000000',
                                lineHeight: '24.38px'
                            }}
                        >Email *</FormLabel>
                        <Input
                            sx={{
                                backgroundColor: 'primary.mainBg',
                                borderRadius: '10px',
                                px: theme.spacing(2.5),
                                py: theme.spacing(2)
                            }}
                            id="input-email"
                            disableUnderline
                            placeholder={'e.g SamSmith@gmail.com'}
                            type={'email'}
                            error={emailError}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {emailError && <div style={{color: 'red'}}>Please enter a valid email address</div>}
                    </FormControl>
                    <FormControl sx={{pt: '49px'}}>
                        <TextField
                            variant={'standard'}
                            InputProps={{
                                disableUnderline: true
                            }}
                            sx={{
                                backgroundColor: 'primary.mainBg',
                                borderRadius: '10px',
                                borderWidth: 0,
                                px: theme.spacing(2.125),
                                py: theme.spacing(3)
                            }}
                            id="outlined-multiline-flexible"
                            placeholder="Tell us a little about your requirements"
                            multiline
                            rows={10}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            // maxRows={10}
                        />
                    </FormControl>

                    <Button
                        type={'submit'}
                        variant={'contained'}
                        fullWidth={false}
                        onClick={handleSubmit}

                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: 'primary.mainBg',
                            borderRadius: '10px',
                            mt: theme.spacing(3.625),
                            mb: theme.spacing(5.625),
                            py: theme.spacing(1.625),
                            px: theme.spacing(8.625),
                            // maxWidth: theme.spacing(25.5),
                            alignSelf: 'center'
                        }}>
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '700',
                            fontSize: '18px',
                            lineHeight: '27px',
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}>
                            Submit
                        </Typography>
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={6} sx={{
                    flex: 1,
                    display: {md: 'flex', sm: 'none', xs: 'none'},
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    pl: '48px'
                }}>
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontWeight: '700',
                        fontSize: '32px',
                        lineHeight: '48px'
                    }}>
                        Ask for free demo
                    </Typography>
                    <Box sx={{mt: '27px'}}>
                        {renderShape1()}
                    </Box>

                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontWeight: '400',
                        fontSize: '20px',
                        lineHeight: '30px',
                        pt: '37px'
                    }}>
                        Our support team will contact you within 24 hours to understand your requirement
                    </Typography>
                    <Box sx={{pt: '35px'}}>
                        <Box sx={{
                            borderRadius: '50%',
                            border: '2px solid',
                            borderColor: 'primary.main',
                            backgroundColor: 'primary.main',
                            width: 99,
                            height: 99,
                            display: 'flex',
                            alignItems: 'center', justifyContent: 'center',
                        }}
                        >
                            <Box component={'img'} src={arrowLeft}/>
                        </Box>
                    </Box>


                </Grid>

            </Grid>

            <ScrollToTop/>
        </Box>
    );
}

const themeOverrides = (theme) => ({
    mainWrapper: {
        background: `url(${background})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
        maxWidth: theme.spacing(125.25),
        alignSelf: 'center',
        py: {md: theme.spacing(12.875), xs: theme.spacing(3)},
        px: {md: 0, xs: theme.spacing(4.75)},
        mt: {md: 0, xs: theme.spacing(4.3)}
    },
    mainHeading: {
        fontFamily: 'Poppins',
        fontWeight: '700',
        fontSize: {md: 48, xs: 24},
        lineHeight: {md: '50px', xs: '25px'},
        textAlign: 'center',
        // justifyContent: 'center',
        color: "#000000",
    },
    subHeadings: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: {md: 16, xs: 14},
        lineHeight: {md: '24px', xs: '21px'},
        textAlign: 'center',
        // justifyContent: 'center'
    },
    topButtonsWrapper: {
        pt: '52px'
    },
    topButtons: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '10px',
        height: 'fit-content',
        px: {md: theme.spacing(4.375), xs: theme.spacing(2.5)},
        py: theme.spacing(2)


    },
    topButtonsText: {
        fontFamily: 'Poppins',
        fontWeight: '600',
        fontSize: {md: '20px', xs: '14px'},
        lineHeight: {md: '30px', xs: '21px'},
        textAlign: 'center',
        justifyContent: 'center',
        color: 'white'
    },
    textStyle1: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '30px',
        textAlign: 'center',
        justifyContent: 'center',
    },
    textStyle2: {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '33px',
        textAlign: 'center',
        justifyContent: 'center',
    }

})

