import {Box, Container, Grid, Link, Typography, useTheme} from "@mui/material";
import {Facebook, Instagram, Twitter} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

export const Footer = (props) => {
    const {sx} = props;
    const theme = useTheme();
    const styles = themeOverrides(theme);
    const navigate = useNavigate();
    return (
        <Container id={'team-members'} maxWidth={false} disableGutters sx={[styles.footerContainer, sx]}>

            {/*<Box sx={{*/}
            {/*    background: `url(${CurvedBg2})`,*/}
            {/*    backgroundRepeat: 'no-repeat',*/}
            {/*    backgroundSize: '100% 100%',*/}
            {/*    width: '100%',*/}
            {/*    mt: theme.spacing(21.125),*/}
            {/*    pb: theme.spacing(16.375)*/}
            {/*}}>*/}
            {/*    <Typography sx={{*/}
            {/*        fontFamily: 'Poppins',*/}
            {/*        fontWeight: '700',*/}
            {/*        fontSize: '36px',*/}
            {/*        lineHeight: '54px',*/}
            {/*        textAlign: 'center',*/}
            {/*        justifyContent: 'center',*/}
            {/*        pt: theme.spacing(7.5),*/}
            {/*        pb: theme.spacing(3.125)*/}
            {/*    }}>*/}
            {/*        Check out what our members say*/}
            {/*    </Typography>*/}
            {/*    {renderShape3()}*/}
            {/*    <Typography sx={{*/}
            {/*        fontFamily: 'Poppins',*/}
            {/*        fontWeight: '400',*/}
            {/*        fontSize: '20px',*/}
            {/*        lineHeight: '30px',*/}
            {/*        textAlign: 'center',*/}
            {/*        justifyContent: 'center',*/}
            {/*        mt: theme.spacing(3.75),*/}
            {/*        px: theme.spacing(33),*/}
            {/*        mb: theme.spacing(4.125)*/}
            {/*    }}>*/}
            {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt. Lorem*/}
            {/*        ipsum dolor sit ame.*/}
            {/*    </Typography>*/}


            {/*    <ImageList cols={COMMENTS.length} gap={36}>*/}
            {/*        {COMMENTS.map((item, index) => (*/}
            {/*            <ImageListItem key={index} sx={{pb: '50px'}}>*/}
            {/*                <Typography sx={{*/}
            {/*                    fontFamily: 'Poppins',*/}
            {/*                    fontWeight: '500',*/}
            {/*                    fontSize: '20px',*/}
            {/*                    lineHeight: '30px',*/}
            {/*                    ml: theme.spacing(5.125)*/}
            {/*                }}>*/}
            {/*                    {item.userType}*/}
            {/*                </Typography>*/}
            {/*                <Box sx={{*/}
            {/*                    backgroundColor: '#FFFFFF',*/}
            {/*                    borderRadius: '20px',*/}
            {/*                    boxShadow: `4px 4px 20px #E5E5E5`,*/}
            {/*                    flex: 1,*/}
            {/*                    width: theme.spacing(43.75),*/}
            {/*                    px: theme.spacing(6.25),*/}
            {/*                    py: theme.spacing(5.5),*/}
            {/*                    alignItems: 'space-between',*/}
            {/*                    display: 'flex',*/}
            {/*                    flexDirection: 'column'*/}
            {/*                }}>*/}

            {/*                    <Box sx={{flex: 1}}>*/}
            {/*                        <Rating*/}
            {/*                            sx={{color: '#FFE665'}}*/}
            {/*                            value={item.rating}*/}
            {/*                            readOnly*/}
            {/*                            color={'#FFE665'}*/}
            {/*                        />*/}
            {/*                        <Typography sx={{*/}
            {/*                            fontFamily: 'Poppins',*/}
            {/*                            fontWeight: '400',*/}
            {/*                            fontSize: '24px',*/}
            {/*                            lineHeight: '29.05px',*/}
            {/*                            mt: theme.spacing(3.89)*/}
            {/*                        }}>*/}
            {/*                            {item.comment}*/}
            {/*                        </Typography>*/}
            {/*                    </Box>*/}
            {/*                    <Box sx={{*/}
            {/*                        display: 'flex',*/}
            {/*                        mt: theme.spacing(5.375),*/}
            {/*                        alignItems: 'center',*/}
            {/*                        justifyContent: 'center'*/}
            {/*                    }}>*/}
            {/*                        <Avatar src={item.userAvatar} sx={{*/}
            {/*                            width: theme.spacing(9.625),*/}
            {/*                            height: theme.spacing(9.625),*/}
            {/*                            mr: theme.spacing(3.5)*/}
            {/*                        }}/>*/}
            {/*                        <Box>*/}
            {/*                            <Typography sx={{*/}
            {/*                                fontFamily: 'Poppins',*/}
            {/*                                fontWeight: '500',*/}
            {/*                                fontSize: '20px',*/}
            {/*                                lineHeight: '24.2px'*/}
            {/*                            }}>*/}
            {/*                                {item.userName}*/}
            {/*                            </Typography>*/}
            {/*                            <Typography sx={{*/}
            {/*                                fontFamily: 'Poppins',*/}
            {/*                                fontWeight: '500',*/}
            {/*                                fontSize: '14px',*/}
            {/*                                lineHeight: '16.94px',*/}
            {/*                                mt: theme.spacing(0.875),*/}
            {/*                                color: '#00000059'*/}
            {/*                            }}>*/}
            {/*                                {item.commentPlatform}*/}
            {/*                            </Typography>*/}
            {/*                        </Box>*/}
            {/*                    </Box>*/}
            {/*                </Box>*/}
            {/*            </ImageListItem>*/}
            {/*        ))}*/}
            {/*    </ImageList>*/}
            {/*</Box>*/}
            <Grid container sx={{
                backgroundColor: '#426F8C',
                color: '#fff',
                px: theme.spacing(9.375),
                pt: theme.spacing(6.75),
                pb: theme.spacing(7.25),
                borderBottom: '1px solid #fff',
                justifyContent: {md: 'none', xs: 'center'},
                textAlign: {xs: 'center', sm: 'center', md: 'start', lg: 'start', xl: 'start'}
            }}>
                <Grid item md={6} sx={{pr: theme.spacing(38)}}>
                    {/*<Typography sx={{*/}
                    {/*    fontFamily: 'Poppins',*/}
                    {/*    fontWeight: '700',*/}
                    {/*    fontSize: '20px',*/}
                    {/*    lineHeight: '30px'*/}
                    {/*}}>*/}
                    {/*    Why AYRA?*/}
                    {/*</Typography>*/}
                    {/*<Typography sx={{*/}
                    {/*    mt: theme.spacing(5.125),*/}
                    {/*    fontFamily: 'Poppins',*/}
                    {/*    fontSize: '16px',*/}
                    {/*    lineHeight: '24px'*/}
                    {/*}}>*/}
                    {/*    Lorem ipsum dolor sit amet consectetur. Aliquam mattis odio condimentum sed. Euismod eu nisl in*/}
                    {/*    tortor*/}
                    {/*    lacus tincidunt.*/}
                    {/*</Typography>*/}
                </Grid>
                <Grid item md={3} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontWeight: '700',
                        fontSize: '20px',
                        lineHeight: '30px'
                    }}>
                        Company
                    </Typography>
                    <Link sx={{
                        mt: theme.spacing(5.125),
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: 'white',
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }} onClick={() => {
                        navigate('/faqs');
                        window.scrollTo({behavior: 'smooth', left: 0, top: 0});
                    }}>
                        FAQs
                    </Link>
                    <Link sx={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: 'white',
                        textDecoration: 'none',
                        mt: theme.spacing(2),
                        cursor: 'pointer'
                    }} onClick={() => {
                        navigate("/");
                        setTimeout(() => {
                            document.getElementById("request-demo").scrollIntoView({behavior: "smooth"});
                        }, 400);

                    }}>
                        Request Demo
                    </Link>
                    <Link sx={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: 'white',
                        textDecoration: 'none',
                        mt: theme.spacing(2),
                        cursor: 'pointer'
                    }} onClick={() => {
                        navigate('/privacy-policy');
                        window.scrollTo({behavior: 'smooth', left: 0, top: 0});
                    }}>
                        Privacy Policy
                    </Link>
                </Grid>
                <Grid item md={3}>
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontWeight: '700',
                        fontSize: '20px',
                        lineHeight: '30px',
                        mt: {md: 0, xs: theme.spacing(8)}
                    }}>
                        Contact Us
                    </Typography>
                    <Typography sx={{
                        mt: theme.spacing(5.125),
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}>
                        Address, Qatar
                    </Typography>
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        lineHeight: '24px',
                        mt: theme.spacing(2)
                    }}>
                        Support@ayra.health
                    </Typography>
                    <Box sx={{mt: theme.spacing(3.75)}}>
                        <Instagram fontSize={'large'}/>
                        <Twitter fontSize={'large'} sx={{mx: theme.spacing(3.4825)}}/>
                        <Facebook fontSize={'large'}/>
                    </Box>

                </Grid>
            </Grid>
            <Typography
                sx={{
                    backgroundColor: '#426F8C',
                    px: {md: theme.spacing(9.375), xs: theme.spacing(2)},
                    py: theme.spacing(3),
                    color: 'white',
                    fontFamily: 'Poppins',
                    fontSize: {md: '16px', xs: '14px'},
                    lineHeight: {md: '24px', xs: '18px'},
                }}>
                Copyright © 2023 AYRA - All Rights Reserved
            </Typography>


        </Container>
    );
}

const themeOverrides = (theme) => ({
    footerContainer: {

        // paddingX: {md: theme.gutterWidth, xs: '20px'},
        paddingTop: '35px'
    },
    footerSection: {
        display: 'flex',
        flexDirection: 'column'
    },
    footerHeading: {
        fontFamily: 'Roboto',
        fontSize: '24px',
        color: '#fff'
    },
    linksContainer: {
        marginY: '15px'
    },
    link: {
        textTransform: 'initial',
        marginY: '5px',
        paddingX: '0px !important',
        cursor: 'pointer',
        color: '#ccc',
        ':hover': {
            color: '#fff'
        },
        display: 'inline-block'
    },
    contactText: {
        textTransform: 'initial',
        marginY: '5px',
        paddingX: '0px !important',
        color: '#ccc',
    },
    socialIconsBox: {
        display: 'flex',
        alignItems: 'center',
        marginY: '10px'
    },
    socialIcon: {
        color: '#ccc',
        ':hover': {
            color: '#fff'
        }
    },
    copyrightBar: {
        borderTop: '1px solid #666',
        paddingY: '10px'
    },
    copyrightText: {
        color: '#ccc'
    }

});
