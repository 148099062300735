import {Box, Button, Collapse, Grid, IconButton, Link, Typography, useTheme} from "@mui/material";
import MainLogo from './../../assets/images/AYRA-logo.png';
import {useState} from "react";
import MenuIcon from './../../assets/vectors/MenuIcon.svg';
import {useLocation, useNavigate} from "react-router-dom";


export const Header = (props) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const {sx} = props;
    const theme = useTheme();
    const styles = themeOverrides(theme);
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <Grid container sx={[styles.navBarContainer, sx]}>
            <Grid item sx={styles.mainHeader}>
                <Link underline={'none'} onClick={() => {
                    navigate('/')
                    window.scrollTo({behavior: 'smooth', left: 0, top: 0});
                }} sx={styles.logoContainer}>
                    <Box component={'img'} src={MainLogo} style={styles.mainLogo} alt={'AYRA Main Logo'}/>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography sx={styles.brandName}>
                            AYRA
                        </Typography>
                        <Typography sx={styles.slogan}>
                            Achieve Your Relief in Asthma
                        </Typography>
                        <Box
                            sx={{
                                borderRadius: '20px',
                                border: '2px solid',
                                backgroundColor: 'primary.main',
                                borderColor: 'primary.main',
                                width: 54,
                                height: 5,
                                visibility: location.pathname === '/' ? 'visible' : 'hidden',
                                display: {md: 'flex', xs: 'none'}
                            }}
                        />

                    </Box>

                </Link>
                <IconButton
                    color="secondary"
                    aria-label="open drawer"
                    onClick={() => {
                        setDrawerOpen(!drawerOpen)
                    }}
                    sx={{
                        display: {xs: 'inline', sm: 'block', md: 'none'}
                    }}
                >
                    <Box component={'img'} src={MenuIcon} sx={{width: "31.89", mr: theme.spacing(1)}}/>
                </IconButton>
                <Box sx={styles.buttonsContainer}>
                    <Button sx={[styles.navigationButton, {mr: theme.spacing(7.75)}]}
                            color={location.pathname === '/' ? 'primary' : 'secondary'}>
                        <Link underline={'none'} onClick={() => {
                            navigate('/faqs')
                            window.scrollTo({behavior: 'smooth', left: 0, top: 0});
                        }} sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '24px',
                            textTransform: 'uppercase',
                            color: 'primary.black',
                            paddingBottom: theme.spacing(1.5)

                        }}>
                            FAQS
                        </Link>
                        <Box
                            sx={{
                                borderRadius: '20px',
                                border: '2px solid',
                                backgroundColor: 'primary.main',
                                borderColor: 'primary.main',
                                width: 54,
                                height: 5,
                                visibility: location.pathname === '/faqs' ? 'visible' : 'hidden',
                                marginBottom: theme.spacing(-1.5)
                            }}
                        />
                    </Button>
                    {/*<Button sx={[styles.navigationButton, {mr: theme.spacing(7.75)}]}*/}
                    {/*        color={location.pathname === '/' ? 'primary' : 'secondary'}>*/}
                    {/*    <Link underline={'none'} onClick={() => {*/}
                    {/*        navigate("/team");*/}
                    {/*        window.scrollTo({behavior: 'smooth', left: 0, top: 0});*/}
                    {/*    }} sx={{*/}
                    {/*        fontFamily: 'Poppins',*/}
                    {/*        fontWeight: '400',*/}
                    {/*        fontSize: '16px',*/}
                    {/*        lineHeight: '24px',*/}
                    {/*        textTransform: 'uppercase',*/}
                    {/*        color: 'primary.black',*/}
                    {/*        paddingBottom: theme.spacing(1.5)*/}

                    {/*    }}>*/}
                    {/*        Team*/}
                    {/*    </Link>*/}
                    {/*    <Box*/}
                    {/*        sx={{*/}
                    {/*            borderRadius: '20px',*/}
                    {/*            border: '2px solid',*/}
                    {/*            backgroundColor: 'primary.main',*/}
                    {/*            borderColor: 'primary.main',*/}
                    {/*            width: 54,*/}
                    {/*            height: 5,*/}
                    {/*            visibility: location.pathname === '/team' ? 'visible' : 'hidden',*/}
                    {/*            marginBottom: theme.spacing(-1.5)*/}
                    {/*        }}*/}
                    {/*    />*/}

                    {/*</Button>*/}
                    <Button sx={[styles.navigationButton, {mr: theme.spacing(7.75)}]}
                            color={location.pathname === '/' ? 'primary' : 'secondary'}>
                        <Link underline={'none'} onClick={() => {
                            navigate('/privacy-policy')
                            window.scrollTo({behavior: 'smooth', left: 0, top: 0});
                        }} sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '24px',
                            textTransform: 'uppercase',
                            color: 'primary.black',
                            paddingBottom: theme.spacing(1.5)

                        }}>
                            Privacy Policy
                        </Link>
                        <Box
                            sx={{
                                borderRadius: '20px',
                                border: '2px solid',
                                backgroundColor: 'primary.main',
                                borderColor: 'primary.main',
                                width: 54,
                                height: 5,
                                visibility: location.pathname === '/privacy-policy' ? 'visible' : 'hidden',
                                marginBottom: theme.spacing(-1.5)
                            }}
                        />

                    </Button>
                    <Button sx={{
                        ...styles.navigationButton,
                        borderRadius: '10px',
                        border: '3px solid #499DD2',
                        py: theme.spacing(2),
                        px: theme.spacing(5)
                    }}
                            color={location.pathname === '/privacy-policy' ? 'primary' : 'secondary'}>
                        <Link underline={'none'} onClick={() => {
                            navigate("/");
                            setTimeout(() => {
                                document.getElementById("request-demo").scrollIntoView({behavior: "smooth"});
                            }, 100);
                        }} sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '700',
                            fontSize: '16px',
                            lineHeight: '24px',
                            textTransform: 'uppercase',
                            color: 'primary'
                        }}>
                            Request Demo
                        </Link>
                    </Button>
                </Box>

            </Grid>
            <Collapse sx={{display: {xs: 'block', sm: 'none',}, width: '100%'}} in={drawerOpen}>
                <Box sx={styles.buttonsMobileContainer}>
                    <Button sx={styles.navigationButtonMobile} color={'primary'}>
                        <Link underline={'none'} onClick={() => {
                            navigate('/faqs')
                            window.scrollTo({behavior: 'smooth', left: 0, top: 0});
                            setDrawerOpen(!drawerOpen)
                        }} sx={styles.navigationLink}>
                            FAQS
                        </Link>
                    </Button>
                    {/*<Button sx={styles.navigationButtonMobile} color={'primary'}>*/}
                    {/*    <Link underline={'none'} onClick={() => {*/}
                    {/*        navigate("/team");*/}
                    {/*        window.scrollTo({behavior: 'smooth', left: 0, top: 0});*/}
                    {/*        setDrawerOpen(!drawerOpen)*/}
                    {/*    }} sx={styles.navigationLink}>*/}
                    {/*        Team*/}
                    {/*    </Link>*/}
                    {/*</Button>*/}
                    <Button sx={styles.navigationButtonMobile} color={'primary'}>
                        <Link underline={'none'} onClick={() => {
                            navigate('/privacy-policy')
                            window.scrollTo({behavior: 'smooth', left: 0, top: 0});
                            setDrawerOpen(!drawerOpen)
                        }} sx={styles.navigationLink}>
                            Privacy Policy
                        </Link>
                    </Button>
                    <Button sx={styles.navigationButtonMobile} color={'secondary'} onClick={() => {
                        navigate("/");
                        setDrawerOpen(!drawerOpen)
                        setTimeout(() => {
                            document.getElementById("request-demo").scrollIntoView({behavior: "smooth"});
                        }, 400);
                    }}>
                        <Link underline={'none'} to={'#'} sx={styles.navigationLink}>
                            Request Demo
                        </Link>
                    </Button>
                </Box>

            </Collapse>
        </Grid>
    );
}

const themeOverrides = (theme) => ({
    navBarContainer: {
        top: 0,
        position: 'sticky',
        backgroundColor: 'white',
        zIndex: 100,
        py: {md: theme.spacing(0), xs: theme.spacing(2)},
        boxShadow: `4px 4px 20px #E5E5E5`,
    },
    mainHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        px: {md: theme.spacing(11), xs: theme.spacing(3)},
    },
    logoContainer: {
        display: 'flex',
        py: {md: theme.spacing(2.75)},
        // flex: 1,
        alignItems: 'center',
        cursor: 'pointer'
    },
    buttonsContainer: {
        display: {md: 'flex', xs: 'none'},
        flex: 2,
        alignItems: 'center',
        justifyContent: 'flex-end',
        py: theme.spacing(0.375)
    },
    buttonsMobileContainer: {
        display: {md: 'none', xs: 'flex'},
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    mainLogo: {
        width: '75px',
        height: '58px',
        margin: '0px 15px',
    },
    brandName: {
        fontFamily: 'Poppins',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '17px',
        textTransform: 'uppercase',
        color: {md: 'primary.black', xs: 'primary.logo'}
    },
    slogan: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '17px',
        textTransform: 'capitalize',
        color: 'primary.black',
        pb: '8px',
        display: {md: 'flex', xs: 'none'}
    },
    navigationButton: {
        textTransform: 'initial',
        paddingX: '15px',
        paddingY: '0px',
        display: 'flex',
        flexDirection: 'column'

    },
    navigationButtonMobile: {
        textTransform: 'initial',
        paddingX: '15px',
        paddingY: '0px',
        display: 'flex',
        flexDirection: 'column',
        my:theme.spacing(3)

    },
    navigationLink: {
        fontFamily: 'Poppins',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '17px',
        color: 'primary.logo'
    }


});
