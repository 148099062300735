import {createTheme} from '@mui/material/styles';

const appTheme = createTheme({
    palette: {
        primary: {
            main: '#499DD2',
            logo: '#426F8C',
            lightblue: '#E0F3FF',
            lightblue1: '#DBEEFF',
            blueish3: '#DCD6F7',
            green4: '#D9FFD9',
            grey2: '#2F4858',
            grey3: '#929AAB',
            grey4: '#CBCED2',
            grey5: '#E8E8EA',
            red: '#EB5757',
            red3: '#EB5757',
            yellow: '#F2C94C',
            smoke1: '#E6EBF0',
            black: '#000000',
            bg:'#73C6FA',
            mainBg: '#FFFFFF'

        },
        secondary: {
            main: '#424874'
        },

    },
    // components: {
    //     MuiTab: {
    //         styleOverrides: {
    //             // Name of the slot
    //             root: {
    //                 // Some CSS
    //                 fontSize: '18px',
    //                 fontFamily: 'Roboto',
    //                 fontWeight: 'normal'
    //             }
    //         },
    //     }
    // },
    // gutterWidth: '190px'
});
export default appTheme;
