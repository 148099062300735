
import {Container} from "@mui/material";
import {Header} from "./header";
import {Outlet} from "react-router-dom";
import {Footer} from "./footer";

export const Layout = ()=> {
    return (
        <Container maxWidth={false} disableGutters={true}>
            <Header/>

            <Outlet/>

            <Footer/>

        </Container>
    );
}
