import {Box, Grid, Link, Typography, useTheme} from "@mui/material";
import BgPattern from './../assets/vectors/banner-pattern.svg';
import CurvedBg1 from "../assets/images/curved-bg-1.png";
import {ScrollToTop} from "../components/common/scrollToTopButton";

export const PrivacyPolicy = () => {
    const theme = useTheme();
    // const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const styles = themeOverrides(theme);

    return (
        <Box>
            <Grid container sx={styles.topSection}>
                <Grid item md={6} sx={{zIndex: 2, pl: theme.spacing(12.625)}}>
                    <Typography sx={[styles.headingText, {
                        mt: theme.spacing(8.5),
                        mb: theme.spacing(12),
                        pr: theme.spacing(23)
                    }]}>
                        <span style={{color: '#499DD2'}}>Privacy Policy</span>
                    </Typography>
                </Grid>
                <Grid item md={6} sx={{pr: theme.spacing(12.625), display: 'flex', justifyContent: 'flex-end'}}>
                </Grid>
            </Grid>
            <Box sx={styles.doctorsSection}>
                <Box sx={styles.halfDescriptionBox}>
                    <Typography sx={[styles.descriptionLevel2Text, {fontWeight: 'bold'}]}>
                        Version: 1.1<br/>
                        Effective Date: 1/1/2023<br/>
                        Last Updated Date: 3/21/2023 <br/><br/>
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>

                        This “Privacy Policy” describes the privacy practices of AYRA HEALTH and our subsidiaries,
                        products, and affiliates (collectively, <strong>“AYRA”, “we”, “us”, or “our”</strong>) with
                        respect to the AYRA.HEALTH website, mobile application, provider portal and the services and
                        resources available or enabled via the Website (collectively, the “<strong>Platform</strong>”),
                        and the rights and choices available to individuals with respect to their information.
                    </Typography>
                    <Typography sx={[styles.specialText]}>
                        Table of Contents
                    </Typography>
                    <ul>
                        <li>
                            <Link
                                sx={styles.link}
                                underline={'none'}
                                onClick={() => document.getElementById('1').scrollIntoView({behavior: 'smooth'})}>
                                Personal Information We Collect
                            </Link>
                        </li>
                        <li>
                            <Link
                                sx={styles.link}
                                underline={'none'}
                                onClick={() => document.getElementById('2').scrollIntoView({behavior: 'smooth'})}>How
                                We Use Your Personal Information
                            </Link>
                        </li>
                        <li>
                            <Link
                                sx={styles.link}
                                underline={'none'}
                                onClick={() => document.getElementById('3').scrollIntoView({behavior: 'smooth'})}>
                                How We Share your Personal Information
                            </Link>
                        </li>
                        <li>
                            <Link
                                sx={styles.link}
                                underline={'none'}
                                onClick={() => document.getElementById('4').scrollIntoView({behavior: 'smooth'})}>
                                Your Choices
                            </Link>
                        </li>
                        <li>
                            <Link
                                sx={styles.link}
                                underline={'none'}
                                onClick={() => document.getElementById('5').scrollIntoView({behavior: 'smooth'})}>
                                Other sites, mobile applications, and services
                            </Link>
                        </li>
                        <li>
                            <Link id={'1'}
                                sx={styles.link}
                                underline={'none'}
                                onClick={() => document.getElementById('6').scrollIntoView({behavior: 'smooth'})}>
                                Security practices
                            </Link>
                        </li>
                        <li>
                            <Link
                                sx={styles.link}
                                underline={'none'}
                                onClick={() => document.getElementById('7').scrollIntoView({behavior: 'smooth'})}>
                                International data transfers
                            </Link>
                        </li>
                        <li>
                            <Link
                                sx={styles.link}
                                underline={'none'}
                                onClick={() => document.getElementById('8').scrollIntoView({behavior: 'smooth'})}>
                                Changes to this Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link
                                sx={styles.link}
                                underline={'none'}
                                onClick={() => document.getElementById('9').scrollIntoView({behavior: 'smooth'})}>
                                How to Contact Us
                            </Link>
                        </li>
              {/*          <li>
                            <Link
                                sx={styles.link}
                                underline={'none'}
                                 onClick={() => document.getElementById('10').scrollIntoView({behavior:'smooth'})}>
                                Your California Privacy Rights</Link>
                        </li>*/}
                    </ul>

                    <Typography sx={[styles.specialText]}>
                        Personal Information We Collect
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>Information you provide to us.</strong> Personal information you provide to us through
                        the Platform or otherwise includes:
                    </Typography>
                    <ul>
                        <li><strong>Business and personal contact information</strong>, such as your first and last
                            name, email and mailing addresses, business address, phone number, professional title,
                            and company name.
                        </li>

                        <li><strong>Content you choose to upload to the Platform</strong>, such as text, images, audio,
                            and video, along with the metadata associated with the files you upload.
                        </li>

                        <li><strong>Profile information</strong>, such as your username and password that you may set to
                            establish an online account with us.
                        </li>
                        <li><strong>Registration information</strong>, such as information that may be related to a
                            service to be provided through the Platform or your User Account.
                        </li>
                        <li><strong>Feedback or correspondence</strong>, such as information you provide when you
                            contact us with questions, feedback, or otherwise correspond with us online.
                        </li>
                        <li><strong>Usage information</strong>, such as information about how you use the Platform and
                            interact with us, including information associated with any content you upload to the
                            Website or otherwise submit to us, and information you provide when you use any interactive
                            features of the Platform.
                        </li>
                        <li><strong>Marketing information</strong>, such as your preferences for receiving
                            communications about our Platform and content thereon, and details about how you engage with
                            our communications.
                        </li>
                        <li><strong>Other information</strong> that we may collect which is not specifically listed
                            here,
                            but which we will use in accordance with this Privacy Policy or as otherwise disclosed at
                            the time of collection.
                        </li>
                        <li><strong>PROTECTED HEALTH INFORMATION</strong> AS USED UNDER THE HEALTH INSURANCE PORTABILITY
                            AND ACCOUNTABILITY ACT OF 1996, AS AMENDED, AND ITS IMPLEMENTING REGULATIONS, IS GOVERNED BY
                            A BUSINESS ASSOCIATE AGREEMENT BETWEEN US, YOUR HEALTHCARE PROVIDER AND/OR YOUR EMPLOYER.
                        </li>
                    </ul>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>Information we obtain from other third parties.</strong> We may receive personal
                        information about you from third-party sources. For example, a health system or hospital who
                        treats the same patient as your employer may share your contact information with us if you or
                        your employer have expressed interest in learning specifically about our Platform, or the types
                        of services you may offer or procure on our Platform.
                    </Typography>


                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>
                            Cookies and Other Information Collected by Automated Means.</strong> We, our vendors, and
                        our business partners may automatically log information about you or your computer, and activity
                        occurring on or through the Platform, including but not limited to, your computer operating
                        system type and version number, manufacturer and model, device identifier (such as the Google
                        Advertising ID or Apple ID for Advertising), browser type, screen resolution, IP address, the
                        website you visited before browsing to our website, general location information such as city,
                        state or geographic area; information about your use of and actions on the Platform, such as
                        pages or screens you viewed, how long you spent on a page or screen, navigation paths between
                        pages or screens, information about your activity on a page or screen, access times, and length
                        of access; and other personal information. Our vendors and business partners may collect this
                        type of information over time and across third-party websites and mobile applications.
                        <br/>
                        <br id={'2'}/>
                        On our webpages, this information is collected using cookies, browser web storage (also known as
                        locally stored objects, or “LSOs”), web beacons, and similar technologies, and our emails may
                        also contain web beacons. A “cookie” is a text file that websites send to a visitor ‘s computer
                        or other Internet-connected device to uniquely identify the visitor’s browser or to store
                        information or settings in the browser. Browser web storage, or LSOs, are used for similar
                        purposes as cookies. Browser web storage enables the storage of a larger amount of data than
                        cookies. A “web beacon,” also known as a pixel tag or clear GIF, is typically used to
                        demonstrate that a webpage was accessed or that certain content was viewed, typically to measure
                        the success of our marketing campaigns or engagement with our emails and to compile statistics
                        about usage of our websites.
                        <br/>
                        <br/>
                        Web browsers may offer users of our Platform the ability to disable receiving certain types of
                        cookies; however, if cookies are disabled, some features or functionality of our Platform may
                        not function correctly. Please see the “Targeted online advertising” section for information
                        about how to exercise choice regarding the use of browsing behavior for purposes of targeted
                        advertising.<br/>
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>
                            Referrals.</strong> Users of the Platform may have the opportunity to refer other colleagues
                        or individuals to us. If you are an existing user, you may only submit a referral if you have
                        permission to provide the referral’s contact information to us so that we may contact them.<br/>

                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong style={{color: theme.palette.primary.main}}>How We Use Your Personal Information?
                        </strong> We use your personal information for the following purposes and as otherwise described
                        in this Privacy Policy or at the time of collection:<br/>
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>To operate the Platform.</strong> We use your personal information to:
                    </Typography>
                    <ul>
                        <li>provide, operate, and improve the Platform.</li>
                        <li>provide information about the services available on the Platform.</li>
                        <li>establish and maintain your User Account on the Platform.</li>
                        <li>facilitate communications features of the Platform, such as by providing chat or messaging
                            functionality.
                        </li>
                        <li>communicate with you about the Platform, including by sending you announcements, updates,
                            security alerts, and support and administrative messages.
                        </li>
                        <li>understand your needs and interests and personalize your experience with the Platform and
                            our communications.
                        </li>
                        <li>provide support and maintenance for the Platform.</li>
                        <li>to respond to your requests, questions, and feedback</li>
                    </ul>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>For research and development.</strong> We analyze use of the Platform to analyze and
                        improve the Platform and to develop new products and services, including by studying user
                        demographics and use of the Platform.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>To send you marketing and promotional communications.</strong> We may send you
                        4AYR-related
                        marketing communications as permitted by law. You will have the ability to opt-out of our
                        marketing and promotional communications as described in the opt out of marketing section below.
                        <br/>
                        <br/>
                        To comply with law. We use your personal information as we believe necessary or appropriate to
                        comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas
                        or requests from government authorities.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>For compliance, fraud prevention, and safety.</strong> We may use your personal
                        information and disclose it to law enforcement, government authorities, and private parties as
                        we believe necessary or appropriate to: (a) protect our, your or others’ rights, privacy, safety
                        or property (including by making and defending legal claims); (b) enforce the terms and
                        conditions that govern the Platform; and (c) protect, investigate and deter against fraudulent,
                        harmful, unauthorized, unethical or illegal activity.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]} id={'3'}>
                        <strong>With your consent.</strong> In some cases, we may specifically ask for your consent to
                        collect,
                        use or share your personal information, such as when required by law.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]} >
                        <strong>To create anonymous, aggregated, or de-identified data.</strong> We may create
                        anonymous, aggregated, or de-identified data from your personal information and other
                        individuals whose personal information we collect. We make personal information into anonymous,
                        aggregated, or de-identified data by removing information that makes the data personally
                        identifiable to you. We may use this anonymous, aggregated, or de-identified data and share it
                        with third parties for our lawful business purposes, including to analyze and improve the
                        Platform and promote our business.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]} >
                        <strong style={{color: theme.palette.primary.main}}>How We Share your Personal
                            Information?</strong> We do not share your personal
                        information with third parties without your consent, except in the following circumstances or as
                        described in this Privacy Policy:
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>Affiliates.</strong> We may share your personal information with our corporate parent,
                        subsidiaries, and affiliates, for purposes consistent with this Privacy Policy.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>Other Users of the Platform.</strong> We may provide functionality that enables you to
                        disclose personal information to other users of the Platform within your account and/or other
                        users who you authorize for such disclosure. We do not control how other users, or third parties
                        use any personal information that you make available to such users or the public. We will not
                        share contact information between users of the Platform until they have consented to us doing
                        so.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>Our Vendors.</strong> We may share your personal information with third party companies
                        and
                        individuals that provide services on our behalf or help us operate the Platform (such as
                        customer support, hosting, analytics, email delivery, marketing, and database management
                        services). These third parties may use your personal information only as directed or authorized
                        by us and in a manner consistent with this Privacy Policy, and we seek to prohibit them from
                        using or disclosing your information for any other purpose.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>Professional advisors.</strong> We may disclose your personal information to
                        professional
                        advisors, such as lawyers, bankers, auditors, and insurers, where necessary in the course of the
                        professional services that they render to us.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]} id={'4'}>
                        <strong>For compliance, fraud prevention and safety.</strong> We may share your personal
                        information
                        for the compliance, fraud prevention and safety purposes described above.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>Business transfers.</strong> We may sell, transfer, or otherwise share some or all our
                        business or assets, including your personal information, in connection with a business
                        transaction (or potential business transaction) such as a corporate divestiture, merger,
                        consolidation, acquisition, reorganization or sale of assets, or in the event of bankruptcy or
                        dissolution.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong style={{color: theme.palette.primary.main}}>Your Choices.</strong> In this section, we
                        describe the rights and choices available to all users.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>Access or Update Your Information.</strong> If you have registered for an account with
                        us,
                        you may review and update certain personal information in your User Account by logging into the
                        account.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>Opting out of marketing communications.</strong> You may opt out of marketing-related
                        emails
                        by following the opt-out or unsubscribe instructions at the bottom of the email, or by
                        contacting us at support@ayra.health. You may continue to receive service-related and other
                        non-marketing emails.
                        <br/>
                        <br/>
                        Cookies & Browser Web Storage. We may allow service providers and other third parties to use
                        cookies and similar technologies to track your browsing activity over time and across the
                        Platform and third-party websites. Most browsers let you remove or reject cookies. To do this,
                        follow the instructions in your browser settings. Many browsers accept cookies by default until
                        you change your settings. Please note that if you set your browser to disable cookies, the
                        Platform may not work properly. Similarly, your browser settings may allow you to clear your
                        browser web storage.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>Do Not Track.</strong> Some Internet browsers may be configured to send “Do Not
                        Track”
                        signals to the online services that you visit. We currently do not respond to “Do Not Track”
                        or similar signals. To find out more about “Do Not Track,” please visit
                        https://www.allaboutdnt.com.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}>
                        <strong>Choosing not to share your personal information.</strong> Where we are required by law
                        to collect your personal information, or where we need your personal information to provide
                        the Platform to you, if you do not provide this information when requested (or you later
                        ask to delete it), we may not be able to provide you with our services. We will tell you
                        what information you must provide to receive the Platform by designating it as required
                        at the time of collection or through other appropriate means.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]} id={'5'}>
                        <strong style={{color: theme.palette.primary.main}}>Other sites, mobile applications, and
                            services.</strong>The Platform may contain links to or integration with other websites,
                        mobile applications, and other online services operated by third parties. Providing such
                        resources are not an endorsement of, or representation that we are affiliated with, any third
                        party. In addition, our content may be included on web pages or in mobile applications or online
                        services that are not associated with us. We do not control third party websites, mobile
                        applications, or online services, and we are not responsible for their actions. Other websites,
                        mobile applications and services follow different rules regarding the collection, use and
                        sharing of your personal information. We encourage you to read the privacy policies of the other
                        websites, mobile applications, and online services you use.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}  id={'6'}>
                        <strong style={{color: theme.palette.primary.main}}>Security practices.</strong> The security
                        of your personal information is important to us. We employ several organizational, technical,
                        and physical safeguards designed to protect the personal information we collect. However,
                        security risk is inherent in all internet and information technologies, and we cannot guarantee
                        the security of your personal information.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]}  id={'7'}>
                        <strong style={{color: theme.palette.primary.main}}>International data transfers.</strong> We
                        are headquartered in the United States and have service providers in other countries, and your
                        personal information may be transferred to the United States or other locations outside of your
                        state, province, or country where privacy laws may not be as protective as those in your state,
                        province, or country.
                    </Typography>
                    <Typography sx={[styles.descriptionLevel2Text]} id={'8'}>
                        <strong style={{color: theme.palette.primary.main}}>Changes to this Privacy
                            Policy.</strong> We reserve the right to modify this Privacy Policy at any time. If we make
                        material changes to this Privacy Policy, we will notify you by updating the date of this Privacy
                        Policy and posting it on the Platform. We may, and if required by law will, also provide
                        notification of changes in another way that we believe is reasonably likely to reach you, such
                        as via e-mail (if you have an account where we have your contact information) or another manner
                        through the Platform. Any modifications to this Privacy Policy will be effective upon our
                        posting the new terms and/or upon implementation of the new changes on the Platform (or as
                        otherwise indicated at the time of posting). In all cases, your continued use of the Platform
                        after the posting of any modified Privacy Policy indicates your acceptance of the terms of the
                        modified Privacy Policy.
                    </Typography>
                    <br/>
                    <Typography sx={[styles.descriptionLevel2Text]} id={'9'}>
                        <strong style={{color: theme.palette.primary.main}}>How to Contact Us</strong><br/>
                        Please direct any questions or comments about this Policy or privacy practices to: <strong
                        style={{color: theme.palette.primary.main}}>support@ayra.health</strong><br/>
                        or you may also write to us via postal mail at:<br/>
                        HHARYA, LLC<br/>
                        Attn: AYRA HEALTH Privacy Group<br/>
                        225 Walnut St, Haddonfield, NJ 08033<br/><br/><br/>
                    </Typography>
                </Box>
            </Box>
            <ScrollToTop/>
        </Box>
    );
}

const themeOverrides = (theme) => ({
    topSection: {
        background: `url(${CurvedBg1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        width: '100%',
        display: 'flex',
    },

    doctorsSection: {
        paddingX: {md: theme.spacing(12.625), xs: '20px'},
        // backgroundColor: '#F5F9F6',
        display: 'flex',
        paddingTop: '35px'
    },
    faqSection: {
        paddingX: {md: theme.gutterWidth, xs: '20px'},
        backgroundColor: '#F5F9F6',
        display: 'flex',
        paddingY: '35px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    faqGrid: {
        marginY: '15px',
        marginTop: {md: '45px', xs: '15px'}
    },
    faqBox: {
        marginX: {md: '10px', xs: '0px'},
        backgroundColor: '#fff',
        display: 'flex',
        borderRadius: '10px',
        height: '100%',
        padding: '10px',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    moreQuestionsBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: {md: '35px', xs: '15px'},
        mt: {md: '15px', xs: '5px'}
    },
    faqItem: {
        display: 'flex',
        flexDirection: 'column',
        marginY: '10px',
        // border: `1px solid ${theme.palette.primary.main}`,
        // padding: '10px',
        // borderRadius: '10px'
    },
    questionText: {
        textAlign: 'center',
        fontSize: {md: '18px', xs: '15px'},
        textTransform: 'initial'
    },
    answerBox: {
        padding: '10px',
        // borderColor: theme.palette.primary.main,
        // borderBottomWidth: '1px',
        // borderTopWidth: '0px',
        // borderLeftWidth: '1px',
        // borderRightWidth: '1px',
        // borderStyle: 'solid'
    },
    answerText: {
        // textAlign: 'center',
        fontSize: '16px',
        color: theme.palette.primary.main
    },
    patientSection: {
        paddingX: {md: theme.gutterWidth, xs: '20px'},
        display: 'flex',
        paddingTop: {md: '35px', xs: '0px'}
    },
    howItWorksSection: {
        backgroundColor: theme.palette.primary.main,
        paddingY: '35px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    getStartedSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: {md: '99px', xs: '43px'}
    },
    stepsGrid: {
        paddingX: {md: theme.gutterWidth, xs: '20px'},
        marginY: '25px'
    },
    step: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '18px',
        m: '5px',
        borderRadius: '10px',
        height: '100%',
    },
    stepText: {
        textAlign: 'center',
        fontSize: '16px'
    },
    stepIcon: {
        width: '70px',
        marginBottom: '15px'
    },
    stepIconMobile: {
        width: '35px',
        marginBottom: '10px'
    },
    halfDescriptionBox: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: {md: '34px', xs: '0px'}
    },
    rightImageBox: {
        flex: 1,
        display: {md: 'flex', xs: 'none'},
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    leftImageBox: {
        flex: 1,
        display: {md: 'flex', xs: 'none'},
        alignItems: 'center'
    },
    sectionImage: {
        maxHeight: '450px',
        width: 'auto'
    },
    sectionImageMobile: {
        maxHeight: '120px',
        width: 'auto'
    },
    headingText: {
        fontFamily: 'Poppins',
        fontWeight: '700',
        fontSize: {md: '27px', xs: '18px'},
        color: theme.palette.primary.main
    },
    descriptionText: {
        fontFamily: 'Poppins',
        fontSize: {md: '18px', xs: '14px'},
        textAlign: 'center'
    },
    descriptionLevel2Text: {
        fontFamily: 'Poppins',
        fontSize: {md: '16px', xs: '13px'},
        mt: '15px',
    },
    specialText: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        fontSize: {md: '17px', xs: '15px'},
        marginY: '14px',
        color: theme.palette.primary.main,
        textAlign: {md: 'left', xs: 'center'},
    },
    descriptionBox: {
        marginTop: '12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingX: '20px'
    },
    downloadAppsBox: {
        backgroundColor: theme.palette.primary.main,
        paddingX: {md: theme.gutterWidth, xs: '20px'},
        backgroundImage: `url(${BgPattern})`,
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: {md: '35px', xs: '15px'}
    },
    storeButtonsContainer: {
        marginTop: {md: '30px', xs: '15px'},
        display: {md: 'block', xs: 'flex'},
        flexDirection: {md: 'row', xs: 'column'}
    },
    storeButton: {
        marginRight: '15px',
        cursor: 'pointer'
    },
    storeButtonMobile: {
        margin: '5px 0px',
        cursor: 'pointer'
    },
    link: {
        mt: theme.spacing(5.125),
        fontFamily: 'Poppins',
        fontSize: '16px',
        lineHeight: '24px',
        color: 'primary',
        textDecoration: 'none',
        cursor: 'pointer'
    }
})

