import { Box, Grid, Link, Typography, useTheme } from '@mui/material';
import CurvedBg1 from '../assets/images/curved-bg-1.png';
import { ScrollToTop } from '../components/common/scrollToTopButton';
import { useLocation } from 'react-router-dom';

export const TermsOfUse = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const language = queryParams.get('lang');
  const isArabic = language === 'ar';
  return isArabic ? <TermsOfUseArabic /> : <TermsOfUseEnglish />;
};

const TermsOfUseArabic = () => {
  const theme = useTheme();
  const styles = themeOverrides({ ...theme, _locale: 'ar-QA' });

  return (
    <Box>
      <Grid
        container
        sx={{ ...styles.topSection, flexDirection: 'row-reverse' }}
      >
        <Grid item md={6} sx={{ zIndex: 2, pl: theme.spacing(12.625) }}>
          <Typography
            sx={[
              styles.headingTextTitle,
              {
                mt: theme.spacing(8.5),
                mb: theme.spacing(12),
                [theme.breakpoints.down('sm')]: { pr: theme.spacing(10) },
              },
            ]}
            dir="rtl"
          >
            <span
              style={{
                color: '#499DD2',
                whiteSpace: 'nowrap',
              }}
            >
              شروط الستخدام
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            pr: theme.spacing(12.625),
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        ></Grid>
      </Grid>
      <Box sx={styles.doctorsSection}>
        <Box sx={styles.halfDescriptionBox} dir="rtl">
          <Typography
            sx={[
              styles.descriptionLevel2Text,
              {
                fontWeight: 'bold',
              },
            ]}
            dir="rtl"
          >
            الإصدار: 1.1
            <br />
            تاريخ النفاذ: 1/1/2024
            <br />
            تاريخ آخر تحديث: 1/1/2024
            <br />
            <br />
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            تتحكم شروط الستخدام التالية في وصولك واستخدامك لوقعنا (
            <Link
              sx={styles.link}
              underline={'none'}
              href="https://ayra.health/"
              target="_blank"
            >
              Ayra.Health
            </Link>
            ) (الشار إليه بـ "الوقع") وبرنامجنا (الشار إليه بـ "البرنامج") وكذلك
            الخدمة القدمة من خلل الوقع والبرنامج (الشار إليها بـ "الخدمة"). من
            خلل الوصول إلى أو استخدام موقعنا أو برنامجنا أو خدمتنا (ما ت ّسمى
            مجتمعًة بـ "Ayra("، فإنك تعلن موافقتك على شروط الستخدام الواردة هنا.
            تعتبر هذه التفاقية عقد (الشار إليه بـ "التفاقية") بينك (الشار إليه
            بـ "الستخدم" أو "أنت") وبي شركة HIKE HEALTH. ( ®الشار إليها بـ
            "الشركة" أو "نحن").
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            نحن نحترم خصوصية مستخدمينا. يرجى الرجوع إلى{' '}
            <Link
              sx={styles.link}
              underline={'none'}
              href="http://localhost:3000/privacy-policy"
              target="_blank"
            >
              سياسة الخصوصية
            </Link>{' '}
            الخاصة بالشركة التي تشرح كيف نجمع ونستخدم ونكشف العلومات. عندما تصل
            إلى أو تستخدم Ayra، فإنك تعلن موافقتك على سياسة الخصوصية هذه.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            ي ّاء القيمي، وطلب الطب، والمارسي الصحيي ال ُسجّلي، والطب Ayra مخصص
            للستخدام فقط من قبل الطباء ال الخرين ومرضاهم. Ayra ل تحّل محل الحكم
            السريري واتخاذ القرارات الطبية التي يمارسها التخصصون الصحيون ول يمكن
            ذلك.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            Ayra غير مخصص للستخدام في تشخيص أو علج حالة طبية. إذا كنت تعاني أو
            تشك في أنك تعاني من مشكلة طبية، يرجى التصال بأخصائي صحي مؤهل على
            الفور.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            بينما تهدف الشركة إلى تقديم معلومات عملية ودقيقة، فإن الشركة تستثني
            وتخلي مسؤوليتها صراحة عن جميع الضمانات بأن العلومات الواردة في Ayra
            محدثة بنسبة ٪100 أو كاملة أو خالية من الخطاء. ل تضمن الشركة دقة أو
            كمال أو فائدة أي معلومات على Ayra، كما أن الشركة ل تتبنى أو تدعم أو
            تتحمل مسؤولية دقة أو موثوقية أي رأي أو نصيحة أو بيان وارد في Ayra.
            الراء والنصائح والبيانات والتوصيات أو العلومات الخرى التاحة من خلل
            Ayra تعبر عن مؤلفيها ول ينبغي بالضرورة العتماد عليها.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            باستخدام Ayra، فإنك توافق على أنك لن تشارك بأي حال من الحوال في
            استخدام خبيث أو تدخل مع Ayra، بما في ذلك على سبيل الثال ل الحصر:
          </Typography>

          <ul>
            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                استخدام Ayra لي غرض غير قانوني أو غير أخلقي أو للترويج لنشطة غير
                قانونية
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                تنفيذ أو محاولة تنفيذ هجوم "حرمان من الخدمة" (Service of Denial)
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                محاولة الوصول إلى أو البحث في Ayra باستخدام أي محرك أو برنامج أو
                أداة أو وكيل أو جهاز أو آلية (بما في ذلك spiders أو robots أو
                crawlers أو أدوات التنقيب عن البيانات أو ما شابه)
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                استخدام أي استخدام آلي لـ Ayra أو اتخاذ أي إجراء تعتبره الشركة
                يفرض أو قد يفرض حملً غير معقول أو غير متناسب على خوادمنا أو
                البنية التحتية للشبكة
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                تعديل أو تكييف أو اختراق Ayra أو تعديل موقع آخر ليهام بأنه مرتبط
                بـ Ayra أو الشركة
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                القيام بأي شيء يعوق التشغيل السليم لشبكة Ayra
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                نسخ أو توزيع Ayra بدون إذن كتابي من الشركة
              </Typography>
            </li>
          </ul>

          <Typography sx={[styles.descriptionLevel2Text]}>
            تحتفظ الشركة بالحق، ولكنها ليست ملزمة، بتقييد أو رفض وصول المستخدم
            إلى Ayra أو اتخاذ إجراءات مناسبة أخرى إذا انتهك المستخدم شروط
            الاستخدام.
          </Typography>

          <Box sx={{ marginTop: 2 }}>
            <Typography sx={[styles.headingText]}>
              إخلء السؤولية عن الضمانات
            </Typography>
            <Typography sx={[styles.descriptionLevel2Text]} fontWeight={500}>
              إلى أقصى حد ممكن، يكون استخدام Ayra على مسؤوليتك الخاصة. يتم توفير
              Ayra " كما هو"، بدون ضمان من أي نوع. بدون تقييد لما سبق، تخلي
              الشركة صراحةً مسؤوليتها عن جميع الضمانات، سواء كانت صريحة أو ضمنية
              أو قانونية، المتعلقة ب Ayra بما في ذلك على سبيل المثال لا الحصر أي
              ضمان بالتسويقية أو الملاءمة لغرض معين أو الملكية أو الأمان أو
              الدقة وعدم الانتهاك. بدون تقييد لما سبق، لا تقدم الشركة أي ضمان أو
              تمثيل بأن الوصول إلى أو تشغيل Ayra سيكون دون انقطاع أو خالٍ من
              الأخطاء والفيروسات. تتحمل أنت المسؤولية الكاملة ومخاطر الخسارة أو
              الضرر الناتج عن تنزيلك و/أو استخدامك للمعلومات أو المحتوى أو
              المواد الأخرى التي تم الحصول عليها من Ayra.
            </Typography>
          </Box>

          <Box sx={{ marginTop: 2 }}>
            <Typography sx={[styles.headingText]}>تحديد المسؤولية</Typography>
            <Typography sx={[styles.descriptionLevel2Text]} fontWeight={500}>
              بموجبه تتنازل عن جميع الحلول أو الضمانات أو المسؤوليات، سواء كانت
              ناشئة من القانون أو غير ذلك. في أي حال من الأحوال لن تكون الشركة
              مسؤولة تجاهك عن أي أضرار من أي نوع كانت، بما في ذلك على سبيل
              المثال لا الحصر الأضرار المباشرة أو غير المباشرة أو الخاصة أو
              التبعية أو العرضية أو العقابية أو غير ذلك الناشئة عن أو متعلقة بأي
              شكل من الأشكال بوصولك أو عرضك أو أي ،Ayra بما في ذلك على سبيل
              المثال لا الحصر اعتمادك على المعلومات أو الآراء التي تظهر على )
              Ayra أو استخدامك ل أو بأي شكل من الأشكال الناشئة عن ،Ayra معلومات
              أو برامج أو مواقع مرتبطة أو منتجات أو خدمات تم الحصول عليها من
              خلال لن تكون الشركة مسؤولة عن الأرباح المفقودة أو فقدان السمعة
              الحسنة أو انقطاع .(Ayra الوصول إلى أو عرض أو استخدام الأعمال أو أي
              أضرار تتعلق بالحاسوب أو فقدان البيانات. يسري هذا التحديد بالكامل
              حتى إذا تم إبلاغ الشركة بإمكانية حدوث الأضرار المذكورة أعلاه. هذا
              التحديد هو عنصر أساسي في الاتفاقية بينك (المستخدم)، والشركة. بدون
              تقييد لما سبق، أنت تمنح الشركة بموجب هذا الإتفاق إبراءً كاملاً
              ونهائيًا وتبرئها وتفصلها عن جميع المطالبات والإجراءات وأسباب
              الدعاوى والدعاوى بما في ،Ayra والإجراءات والمطالب من أي نوع كانت،
              التي قد تكون لديك ضد الشركة، والتي تتعلق ب: ( 1) وصولك واستخدامك ل
              ،Ayra بما في ذلك النزاع بينك وبين أي مستخدمين آخرين ل ؛Ayra و ( 2)
              أي معلومات تحصل عليها من خلال Ayra.
            </Typography>
          </Box>

          <Typography sx={[styles.descriptionLevel2Text]}>
            يمكننا تعديل شروط الاستخدام هذه في أي وقت وسنقوم بتحديث شروط
            الاستخدام في حالة إجراء أي تعديلات. تكمن مسؤوليتك فإنك تعلن ،Ayra
            الوحيدة مراجعة الموقع أو البرنامج من وقت لآخر للاطلاع على أي تغييرات
            في الاتفاقية. إذا واصلت استخدام موافقتك على تعديلاتنا على هذه شروط
            الاستخدام. لن تكون أي تغييرات على شروط الاستخدام (باستثناء ما ورد في
            هذه الفقرة) أو التنازل عن حقوق الشركة هنا سارية أو فعالة إلا في
            اتفاقية مكتوبة تحمل التوقيع الفعلي لمسؤول من الشركة. لن يكون أي
            تنازل أو تعديل مزعوم لهذه الاتفاقية من قبل الشركة عبر الاتصالات
            الهاتفية أو البريد الإلكتروني ساريًا.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            لا تدعي الشركة أي حقوق ملكية على التوصيات أو النصائح التي يتم
            تقديمها من خلال Ayra.
          </Typography>

          <Box sx={{ marginTop: 2 }}>
            <Typography sx={[styles.headingText]}>رخصة محدودة</Typography>
            <Typography sx={[styles.descriptionLevel2Text]}>
              تمتلك الشركة وحدها (ومرخصوها، حيثما ينطبق) جميع الحقوق والملكية
              والمصلحة، بما في ذلك جميع حقوق الملكية الفكرية المتعلقة ب Ayra.
              اسم الشركة وشعار الشركة وأسماء المنتجات المرتبطة ب Ayraهي علامات
              تجارية للشركة أو لأطراف ثالثة، ولا يتم منح أي حق أو ترخيص
              لاستخدامها. لا يمكنك إعداد أعمال مشتقة من Ayra بدون موافقة كتابية
              صريحة من الشركة. يجب الحصول على إذن كتابي مسبقاً عبر البريد
              الإلكتروني أو البريد العادي في حالة إعادة إنتاج المواد من Ayra في
              منشور آخر أو موقع ويب.
            </Typography>
          </Box>

          <Typography sx={[styles.descriptionLevel2Text]} fontWeight={500}>
            بدون تقييد لما سبق. يُحظر نسخ أو إعادة إنتاج البرنامج (AYRA) على أي
            خادم أو موقع آخر لإعادة الإنتاج أو التوزيع.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            إذا تم اعتبار أي جزء من هذه الاتفاقية باطلاً أو غير قابل للتنفيذ،
            فسيتم تفسير هذا الجزء من الاتفاقية بما يتماشى مع القانون المعمول به.
            ستظل الأجزاء المتبقية سارية بالكامل. لن يُعتبر أي فشل من جانب الشركة
            في تنفيذ أي بند من هذه الاتفاقية تنازلاً عن حقنا في تنفيذ هذا البند.
            ستستمر حقوق الشركة بموجب هذه الاتفاقية بعد أي إنهاء لهذه الاتفاقية.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            سيتم تفسير شروط الاستخدام وفقًا للقوانين المعمول بها في ولاية نيو
            جيرسي (الولايات المتحدة الأمريكية)، دون اعتبار لتعارضها مع أحكام
            القانون. بموجب ذلك، توافق الأطراف (أنت ونحن)، على أي مطالبة أو دعوى
            قضائية تنتج عن أي سبب كان يتعلق بشروط على اللجوء إلى المنطقة
            القضائية في هادونفيلد، نيو جيرسي، الولايات المتحدة الأمريكية،
            باعتبارها المكان ،Ayra الاستخدام أو أو في أي مكان آخر (Quebec)
            المناسب لسماع تلك المطالبة أو الدعوى القضائية واستبعاد أي منطقة
            قضائية أخرى في كيبيك قد تكون لها اختصاص فيما يتعلق بأي مسألة من هذا
            القبيل.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            قد تقوم الشركة بتعيين أو تفويض شروط الاستخدام هذه و/أو سياسة
            الخصوصية الخاصة بالشركة، كليًا أو جزئيًا، إلى أي شخص أو كيان في أي
            وقت دون موافقتك، طالما أن هذا المحال إليه أو المفوض يوافق على الشروط
            المنصوص عليها هنا. لا يجوز لك تعيين أو تفويض أي حقوق أو التزامات
            بموجب شروط الاستخدام أو سياسة الخصوصية بدون موافقة كتابية مسبقة من
            الشركة، وأي تعيين أو تفويض غير مصرح به من قبلك يكون باطلاً.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]} fontWeight={500}>
            تقر بأنك قد قرأت شروط الاستخدام هذه، وفهمت شروط الاستخدام، وستلتزم
            بهذه الشروط والأحكام. كما تقر أيضًا بأن شروط الاستخدام هذه مع{' '}
            <Link
              sx={styles.link}
              underline={'none'}
              href="https://ayra.health/privacy-policy"
              target="_blank"
            >
              سياسة الخصوصية
            </Link>{' '}
            تمثل البيان الكامل والحصري للاتفاقية بينك (المستخدم)، والشركة وأنها
            تحل محل أي اقتراح أو اتفاقية سابقة شفوية أو مكتوبة، وأي اتصالات أخرى
            بيننا تتعلق بموضوع هذه الاتفاقية.
          </Typography>

          <br />
          <br />
          <br />
        </Box>
      </Box>
      <ScrollToTop />
    </Box>
  );
};

const TermsOfUseEnglish = () => {
  const theme = useTheme();
  const styles = themeOverrides(theme);

  return (
    <Box>
      <Grid container sx={styles.topSection}>
        <Grid item md={6} sx={{ zIndex: 2, pl: theme.spacing(12.625) }}>
          <Typography
            sx={[
              styles.headingTextTitle,
              {
                mt: theme.spacing(8.5),
                mb: theme.spacing(12),
                pr: theme.spacing(23),
              },
            ]}
          >
            <span style={{ color: '#499DD2', whiteSpace: 'nowrap' }}>
              Terms Of Use
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            pr: theme.spacing(12.625),
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        ></Grid>
      </Grid>
      <Box sx={styles.doctorsSection}>
        <Box sx={styles.halfDescriptionBox}>
          <Typography
            sx={[styles.descriptionLevel2Text, { fontWeight: 'bold' }]}
          >
            Version: 1.1
            <br />
            Effective Date: 1/1/2024
            <br />
            Last Updated Date: 1/1/2024 <br />
            <br />
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            The following Terms of Use govern and apply to your access and use
            of our website ({' '}
            <Link
              sx={styles.link}
              underline={'none'}
              href="https://ayra.health/"
              target="_blank"
            >
              Ayra.Health
            </Link>
            ) (the “Site”) and our software (the “Software”), as well as the
            service provided through the Site and Software (the “Service”). By
            accessing or using our Site, Software, or Service (collectively
            “Ayra”), you signify your agreement to these Terms of Use. This is a
            contract (the “Agreement”) between you (the “User” or “you”) and
            HIKE HEALTH®. (the “Company” or “we”).
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            We respect the privacy of our users. Please refer to the Company’s{' '}
            <Link
              sx={styles.link}
              underline={'none'}
              href="http://localhost:3000/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Link>{' '}
            which explains how we collect, use, and disclose information. When
            you access or use Ayra, you signify your agreement to this Privacy
            Policy.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            Ayra is intended for use only by registered physicians, medical
            residents, medical students, and other licensed healthcare
            practitioners and their patients. Ayra does not and cannot replace
            the clinical judgment and medical decision making that is exercised
            by a healthcare professional.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            Ayra is not intended for use to diagnose or treat a medical
            condition. If you have or suspect that you have a medical problem or
            condition, please contact a qualified healthcare professional
            immediately.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            While the Company aims at delivering practical and accurate
            information, the Company expressly excludes and disclaims all
            warranties that the information contained in Ayra is 100%
            up-to-date, complete, or error-free. The Company does not guarantee
            the accuracy, completeness, or usefulness of any information on
            Ayra, and neither does the Company adopt, endorse, nor is the
            Company responsible for the accuracy or reliability of any opinion,
            advice, or statement contained in Ayra. Opinions, advice,
            statements, recommendations, or other information made available
            through Ayra are those of their respective authors and should not
            necessarily be relied upon.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            By using Ayra, you agree that you will not under any circumstances
            engage in malicious use or interference with Ayra, including without
            limitation:
          </Typography>

          <ul>
            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                Use Ayra for any unlawful or immoral purpose or for the
                promotion of illegal activities
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                Execute or attempt to execute a “Denial of Service” attack
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                Attempt to access or search Ayra through the use of any engine,
                software, tool, agent, device, or mechanism (including spiders,
                robots, crawlers, data mining tools, or the like)
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                Make any automated use of Ayra or take any action that the
                Company deems to impose or to potentially impose an unreasonable
                or disproportionately large load on our servers or network
                infrastructure
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                Modify, adapt, or hack Ayra or modify another website so as to
                falsely imply that it is associated with Ayra or the Company
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                Do anything which impairs the proper operation of Ayra’s network
              </Typography>
            </li>

            <li>
              <Typography sx={[styles.descriptionLevel2TextListItem]}>
                Copy or distribute Ayra without written permission from the
                Company
              </Typography>
            </li>
          </ul>

          <Typography sx={[styles.descriptionLevel2Text]}>
            The Company reserves the right, but has no obligation, to limit or
            deny a user’s access to Ayra or take other appropriate action if a
            user violates these Terms of Use.
          </Typography>

          <Box sx={{ marginTop: 2 }}>
            <Typography sx={[styles.headingText]}>
              Warranty Disclaimer
            </Typography>
            <Typography sx={[styles.descriptionLevel2Text]} fontWeight={500}>
              TO THE FULLEST EXTENT POSSIBLE, USE OF AYRA IS AT YOUR OWN RISK.
              AYRA IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT
              LIMITING THE FOREGOING, THE COMPANY EXPRESSLY DISCLAIMS ALL
              WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, REGARDING AYRA
              INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY, AND
              NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, THE COMPANY
              MAKES NO WARRANTY OR REPRESENTATION THAT ACCESS TO OR OPERATION OF
              AYRA WILL BE UNINTERRUPTED OR ERROR-FREE OR FREE OF BUGS AND
              VIRUSES. YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS OR DAMAGE
              RESULTING FROM YOUR DOWNLOADING AND/OR USE OF INFORMATION,
              CONTENT, OR OTHER MATERIAL OBTAINED FROM AYRA.
            </Typography>
          </Box>

          <Box sx={{ marginTop: 2 }}>
            <Typography sx={[styles.headingText]}>
              Limitation of Liability
            </Typography>
            <Typography sx={[styles.descriptionLevel2Text]} fontWeight={500}>
              YOU HEREBY WAIVE ALL REMEDIES, WARRANTIES, GUARANTEES, OR
              LIABILITIES, ARISING FROM LAW OR OTHERWISE. IN NO EVENT SHALL THE
              COMPANY BE LIABLE TO YOU FOR ANY DAMAGES WHATSOEVER, HOWEVER
              CAUSED, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, SPECIAL,
              CONSEQUENTIAL, INCIDENTAL, PUNITIVE, OR OTHERWISE ARISING OUT OF,
              OR IN ANY WAY CONNECTED WITH, YOUR ACCESS, DISPLAY, OR USE OF AYRA
              (INCLUDING BUT NOT LIMITED TO YOUR RELIANCE UPON INFORMATION OR
              OPINIONS THAT APPEAR ON AYRA, ANY INFORMATION, SOFTWARE, LINKED
              SITES, PRODUCTS, AND SERVICES OBTAINED THROUGH AYRA, OR OTHERWISE
              ARISING OUT OF THE ACCESS TO, DISPLAY OF, OR USE OF AYRA). THE
              COMPANY SHALL NOT BE LIABLE FOR LOST PROFITS, LOSS OF GOODWILL,
              BUSINESS INTERRUPTION, ANY COMPUTER-RELATED DAMAGE OR LOSS OF
              DATA. THIS ENTIRE LIMITATION SHALL APPLY EVEN IF THE COMPANY WAS
              INFORMED OF THE POSSIBLE OCCURRENCE OF THE ABOVEMENTIONED DAMAGES.
              THIS LIMITATION IS AN ESSENTIAL ELEMENT OF THE AGREEMENT BETWEEN
              YOU, THE USER, AND THE COMPANY. WITHOUT LIMITING THE FOREGOING,
              YOU HEREBY GRANT TO THE COMPANY A FULL AND FINAL RELEASE AND
              FOREVER ACQUIT AND DISCHARGE THE COMPANY OF AND FROM ALL CLAIMS,
              ACTIONS, CAUSES OF ACTION, SUITS, PROCEEDINGS, AND DEMANDS OF
              WHATSOEVER NATURE, CHARACTER, OR KIND WHICH YOU CAN, SHALL OR MAY
              HAVE AGAINST THE COMPANY, WHICH RELATE TO: (I) YOUR ACCESS AND USE
              OF AYRA, INCLUDING A DISPUTE BETWEEN YOU AND ANY OTHER USERS OF
              AYRA; AND (II) ANY INFORMATION WHICH YOU OBTAIN THROUGH AYRA.
            </Typography>
          </Box>

          <Typography sx={[styles.descriptionLevel2Text]}>
            We can amend these Terms of Use at any time and will update these
            Terms of Use in the event of any such amendments. It is your sole
            responsibility to check the Site or the Software from time to time
            to view any such changes in the Agreement. If you continue to use
            Ayra, you signify your agreement to our revisions to these Terms of
            Use. Any changes to these Terms of Use (other than as set forth in
            this paragraph) or waiver of the Company’s rights hereunder shall
            not be valid or effective except in a written agreement bearing the
            physical signature of an officer of the Company. No purported waiver
            or modification of this Agreement by the Company via telephone or
            email communications shall be valid.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            The Company does NOT claim any ownership rights on the
            recommendations or advice that are provided through Ayra.
          </Typography>

          <Box sx={{ marginTop: 2 }}>
            <Typography sx={[styles.headingText]}>Limited License</Typography>
            <Typography sx={[styles.descriptionLevel2Text]}>
              The Company alone (and its licensors, where applicable) shall own
              all right, title and interest, including all related intellectual
              property rights, in and to Ayra. The Company name, the Company
              logo, and the product names associated with Ayra are trademarks of
              the Company or third parties, and no right or license is granted
              to use them. You may not prepare derivative works of Ayra without
              the Company’s explicit written consent. Permission to reproduce
              material from Ayra in another publication or website must be
              secured in writing in advance via e-mail or regular mail.
            </Typography>
          </Box>

          <Typography sx={[styles.descriptionLevel2Text]} fontWeight={500}>
            WITHOUT LIMITING THE FOREGOING, COPYING OR REPRODUCTION OF THE
            SOFTWARE TO ANY OTHER SERVER OR LOCATION FOR FURTHER REPRODUCTION OR
            REDISTRIBUTION IS EXPRESSLY PROHIBITED.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            If any part of this Agreement is held invalid or unenforceable, that
            portion of the Agreement will be construed consistent with
            applicable law. The remaining portions will remain in full force and
            effect. Any failure on the part of the Company to enforce any
            provision of this Agreement will not be considered a waiver of our
            right to enforce such provision. The Company’s rights under this
            Agreement will survive any termination of this Agreement.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            These Terms of Use will be interpreted in accordance with the laws
            applicable in the State of New Jersey (USA), without regard to its
            conflict-of-law provisions. You and we hereby agree, for any claim
            or lawsuit resulting from any reason whatsoever in relation with
            these Terms of Use or Ayra, to attorn to the judicial district of
            Haddonfield, New Jersey, USA, as the appropriate location for the
            hearing of said claim or lawsuit and excluding any other judicial
            district of Quebec or elsewhere which might have jurisdiction with
            respect to any such matter.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            The Company may assign or delegate these Terms of Use and/or the
            Company’s Privacy Policy, in whole or in part, to any person or
            entity at any time with or without your consent, so long as such
            assignee or delegate agrees to the terms set forth herein. You may
            not assign or delegate any rights or obligations under the Terms of
            Use or Privacy Policy without the Company’s prior written consent,
            and any unauthorized assignment and delegation by you is void.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]} fontWeight={500}>
            YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE, UNDERSTAND
            THE TERMS OF USE, AND WILL BE BOUND BY THESE TERMS AND CONDITIONS.
            YOU FURTHER ACKNOWLEDGE THAT THESE TERMS OF USE TOGETHER WITH THE{' '}
            <Link
              sx={styles.link}
              underline={'none'}
              href="https://ayra.health/privacy-policy"
              target="_blank"
            >
              PRIVACY POLICY
            </Link>{' '}
            REPRESENT THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT
            BETWEEN YOU, THE USER, AND THE COMPANY AND THAT IT SUPERSEDES ANY
            PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY OTHER
            COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF THIS
            AGREEMENT.
          </Typography>

          <Typography sx={[styles.descriptionLevel2Text]}>
            You have agreed that these Terms of Use be drafted in English.
          </Typography>

          <br />
          <br />
          <br />
        </Box>
      </Box>
      <ScrollToTop />
    </Box>
  );
};

const themeOverrides = (theme) => ({
  topSection: {
    background: `url(${CurvedBg1})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    width: '100%',
    display: 'flex',
  },

  doctorsSection: {
    paddingX: { md: theme.spacing(12.625), xs: '20px' },
    display: 'flex',
    paddingTop: '35px',
  },

  halfDescriptionBox: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: { md: '34px', xs: '0px' },
  },

  headingText: {
    fontFamily: 'Poppins',
    fontWeight: { md: '700', xs: '500' },
    fontSize: { md: '24px', xs: '16px' },
    color: theme.palette.primary.main,
  },

  headingTextTitle: {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: { md: '27px', xs: '18px' },
    color: theme.palette.primary.main,
  },
  descriptionLevel2Text: {
    fontFamily: 'Poppins',
    fontSize: { md: '16px', xs: '13px' },
    mt: '15px',
  },
  descriptionLevel2TextListItem: {
    fontFamily: 'Poppins',
    fontSize: { md: '16px', xs: '13px' },
    mb: '2px',
  },
  link: {
    mt: theme.spacing(5.125),
    fontFamily: 'Poppins',
    fontSize: { md: '16px', xs: '13px' },
    lineHeight: '24px',
    color: 'primary',
    textDecoration: 'none',
    cursor: 'pointer',
  },
});
