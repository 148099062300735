import {Box, Button, Grid, Link, Typography, useTheme} from "@mui/material";
import CurvedBg1 from './../assets/images/curved-bg-1.png';
import FaqHeader1 from './../assets/vectors/faq-header-1.svg';
import BgPattern from './../assets/vectors/banner-pattern.svg';
import {PATIENT_FAQS, PROVIDER_FAQS} from "../constants/common-data";
import {useState} from "react";
import {Add, Remove} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {ScrollToTop} from "../components/common/scrollToTopButton";

export const FAQs = () => {
    const [activeFaq, setActiveFaq] = useState(0);
    const [forProvider, setForProvider] = useState(true);
    const theme = useTheme();
    // const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const styles = themeOverrides(theme);
    const questions = forProvider ? PROVIDER_FAQS : PATIENT_FAQS;
    return (
        <Box sx={{backgroundColor: 'white', mb: theme.spacing(7.5)}}>
            <ScrollToTop/>
            <Grid container sx={styles.topSection}>
                <Box sx={{
                    width: theme.spacing(42.375),
                    height: theme.spacing(42.375),
                    backgroundColor: '#DAEEFF',
                    mt: theme.spacing(10.25),
                    position: 'absolute',
                    left: {md: theme.spacing(-10), xs: theme.spacing(-32)},
                    borderRadius: '50%',
                    zIndex: 1
                }}/>
                <Box sx={{
                    width: theme.spacing(15.625),
                    height: theme.spacing(15.625),
                    backgroundColor: '#DAEEFF',
                    mt: theme.spacing(6.125),
                    position: 'absolute',
                    left: {md: theme.spacing(39.875), xs: theme.spacing(19)},
                    borderRadius: '50%',
                    zIndex: 1
                }}/>
                <Grid item md={6} sx={{zIndex: 2, pl: {md: theme.spacing(12.625), xs: theme.spacing(6)}}}>
                    <Typography sx={[styles.headingText,
                        {
                            mt: {
                                md: theme.spacing(23.5),
                                xs: theme.spacing(8.375)
                            },
                            pr: {
                                md: theme.spacing(23),
                                xs: theme.spacing(12.375)
                            }
                        }]}>
                        <span style={{color: '#499DD2'}}>Your well being</span> is our priority
                    </Typography>
                    <Typography sx={[styles.descriptionText, {
                        mt: {md: theme.spacing(3.125), xs: theme.spacing(1.125)},
                        pr: theme.spacing(13)
                    }]}>
                        Achieve the best control of your asthma to enjoy an active lifestyle with our FAQ’s
                    </Typography>
                    <Button sx={{
                        ...styles.navigationButton,
                        borderRadius: '10px',
                        border: '3px solid #499DD2',
                        py: theme.spacing(2),
                        px: theme.spacing(5),
                        mt: theme.spacing(4),
                        display: {md: 'flex', xs: 'flex', sm: 'flex', lg: 'none', xl: 'none'}
                    }}
                            onClick={() => {
                                navigate("/");
                                setTimeout(() => {
                                    document.getElementById("request-demo").scrollIntoView({behavior: "smooth"});
                                }, 400);
                            }}
                            color={'primary'}>
                        <Link underline={'none'} onClick={() => {
                            // navigate('/privacy-policy')
                        }} sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '700',
                            fontSize: '16px',
                            lineHeight: {md: '24px', xs: '12px'},
                            textTransform: 'uppercase',
                            color: 'primary',

                        }}>
                            Request Demo
                        </Link>
                    </Button>
                </Grid>

                <Grid item md={6} sx={{
                    pr: {md: theme.spacing(12.625), xs: theme.spacing(1)},
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flex: 1
                }}>
                    <Box
                        component={'img'}
                        src={FaqHeader1}
                        sx={{
                            mt: {md: theme.spacing(12), xs: -10},
                            mb: {md: theme.spacing(17.5), xs: theme.spacing(0)}
                        }}/>
                </Grid>
            </Grid>
            <Box sx={styles.faqSection}>
                <Typography sx={styles.faqHeadingText}>
                    FAQs
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'center', mt: theme.spacing(1.25)}}>
                    <Box sx={{
                        width: theme.spacing(0.763),
                        height: theme.spacing(0.625),
                        borderRadius: '20px',
                        backgroundColor: 'primary.main',
                        mr: theme.spacing(0.555)
                    }}/>
                    <Box sx={{
                        width: theme.spacing(4.965),
                        height: theme.spacing(0.625),
                        borderRadius: '20px',
                        backgroundColor: 'primary.main',
                        mr: theme.spacing(0.555)
                    }}/>
                    <Box sx={{
                        width: theme.spacing(0.763),
                        height: theme.spacing(0.625),
                        borderRadius: '20px',
                        backgroundColor: 'primary.main',
                    }}/>
                </Box>
                <Typography sx={[styles.descriptionText, {
                    mt: {md: theme.spacing(3.125), xs: theme.spacing(5.625)},
                    px: {md: theme.spacing(13), xs: theme.spacing(4)},
                    textAlign: 'center'
                }]}>
                    Understand how to navigate the mobile app effectively through technical FAQ's.
                    <br/>
                    Use our Asthma facts to learn and manage your asthma.
                </Typography>
            </Box>
            <Box sx={{
                mt: {md: theme.spacing(8), xs: theme.spacing(4)},
                px: {md: theme.spacing(12.125), xs: theme.spacing(6)},
                display: 'flex'
            }}>
                <Button
                    variant={forProvider ? 'contained' : 'outlined'}
                    onClick={() => {
                        if (!forProvider) {
                            setForProvider(true);
                            setActiveFaq(0);
                        }
                    }}
                    sx={[styles.topButtons, {mr: theme.spacing(2.875), color: forProvider ? 'white' : '#499DD2'}]}>
                    <Typography sx={styles.topButtonsText}>
                        Health care provider
                    </Typography>

                </Button>
                <Button
                    variant={!forProvider ? 'contained' : 'outlined'}
                    onClick={() => {
                        if (forProvider) {
                            setForProvider(false);
                            setActiveFaq(0);
                        }
                    }}
                    sx={[styles.topButtons, {color: forProvider ? '#499DD2' : 'white'}]}>
                    <Typography sx={styles.topButtonsText}>
                        Patients
                    </Typography>

                </Button>
            </Box>
            <Box sx={styles.faqBox}>
                {
                    questions.map((item, index) => {
                        return (
                            <Box sx={styles.faqItem} key={`provider-faq-${index}`}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderBottom: '2px solid #73C6FA',
                                    py: {md: theme.spacing(2), xs: theme.spacing(0.1)},
                                    mb: theme.spacing(1)
                                }}>
                                    <Typography
                                        sx={[styles.questionText, {color: activeFaq === index ? '#499DD2' : '#000'}]}>
                                        {item.question}
                                    </Typography>
                                    <Button
                                        onClick={() => {
                                            if (activeFaq === index) {
                                                setActiveFaq(-1);
                                            } else {
                                                setActiveFaq(index);
                                            }

                                        }}>
                                        {activeFaq === index ? (<Remove color={'#73C6FA'}/>) : (
                                            <Add color={'#73C6FA'}/>)}
                                    </Button>
                                </Box>

                                {
                                    activeFaq === index && (
                                        <Box sx={styles.answerBox}>

                                            <Typography
                                                sx={styles.answerText}
                                                dangerouslySetInnerHTML={{
                                                    __html: item.answer
                                                }}
                                            />
                                        </Box>
                                    )
                                }

                            </Box>
                        )
                    })
                }

            </Box>
            <Typography sx={{
                mt: {md: theme.spacing(24), xs: theme.spacing(10)},
                backgroundColor: '#F0F8FF',
                textAlign: 'center',
                py: theme.spacing(1.3),
                px: {md: theme.spacing(0), xs: theme.spacing(7)},
                color: '#499DD2',
                fontFamily: 'Poppins',
                fontSize: {md: '20px', xs: '16px'},
                lineHeight: {md: '30px', xs: '24px'},
                fontWeight: '600'
            }}>
                Created by certified pulmonologists in accordance with latest international guidelines
            </Typography>
        </Box>
    );
}

const themeOverrides = (theme) => ({
    topSection: {
        background: `url(${CurvedBg1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        width: '100%',
        display: 'flex',
        paddingBottom: '20px'
    },

    doctorsSection: {
        paddingX: {md: theme.gutterWidth, xs: '20px'},
        backgroundColor: '#F5F9F6',
        display: 'flex',
        paddingTop: '35px'
    },
    faqSection: {
        paddingX: {md: theme.gutterWidth, xs: '20px'},
        display: 'flex',
        paddingY: '35px',
        flexDirection: 'column',
        alignItems: 'center',
        mt: {md: theme.spacing(11.11), xs: theme.spacing(6.5)},
    },
    faqGrid: {
        marginY: '15px',
        marginTop: {md: '45px', xs: '15px'}
    },
    faqBox: {
        marginX: {md: theme.spacing(12.125), xs: theme.spacing(6)},
        backgroundColor: '#fff',
        display: 'flex',
        borderRadius: '10px',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        mt: {md: theme.spacing(15), xs: theme.spacing(8.2)}
    },
    moreQuestionsBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: {md: '35px', xs: '15px'},
        mt: {md: '15px', xs: '5px'}
    },
    faqItem: {
        display: 'flex',
        flexDirection: 'column',
        marginY: '10px',
        // border: `1px solid ${theme.palette.primary.main}`,
        // padding: '10px',
        // borderRadius: '10px'
    },
    questionText: {
        fontFamily: 'Poppins',
        fontWeight: '500px',
        fontSize: {md: '20px', xs: '16px'},
        lineHeight: {md: '30px', xs: '24px'},
        textTransform: 'initial'
    },
    answerBox: {
        py: {md: theme.spacing(6.125), xs: theme.spacing(3)},
        pr: {md: theme.spacing(15), xs: 0}
        // borderColor: theme.palette.secondary.main,
        // borderBottomWidth: '1px',
        // borderTopWidth: '0px',
        // borderLeftWidth: '1px',
        // borderRightWidth: '1px',
        // borderStyle: 'solid'
    },
    answerText: {
        fontFamily: 'Poppins',
        // textAlign: 'center',
        fontSize: {md: '20px', xs: '14px'},
        lineHeight: {md: '30px', xs: '21px'},

        color: '#000'
    },
    patientSection: {
        paddingX: {md: theme.gutterWidth, xs: '20px'},
        display: 'flex',
        paddingTop: {md: '35px', xs: '0px'}
    },
    howItWorksSection: {
        backgroundColor: theme.palette.primary.main,
        paddingY: '35px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    getStartedSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: {md: '99px', xs: '43px'}
    },
    stepsGrid: {
        paddingX: {md: theme.gutterWidth, xs: '20px'},
        marginY: '25px'
    },
    step: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '18px',
        m: '5px',
        borderRadius: '10px',
        height: '100%',
    },
    stepText: {
        textAlign: 'center',
        fontSize: '16px'
    },
    stepIcon: {
        width: '70px',
        marginBottom: '15px'
    },
    stepIconMobile: {
        width: '35px',
        marginBottom: '10px'
    },
    halfDescriptionBox: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: {md: '34px', xs: '0px'}
    },
    rightImageBox: {
        flex: 1,
        display: {md: 'flex', xs: 'none'},
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    leftImageBox: {
        flex: 1,
        display: {md: 'flex', xs: 'none'},
        alignItems: 'center'
    },
    sectionImage: {
        maxHeight: '450px',
        width: 'auto'
    },
    sectionImageMobile: {
        maxHeight: '120px',
        width: 'auto'
    },
    headingText: {
        fontFamily: 'Poppins',
        fontWeight: '700',
        fontSize: {md: '48px', xs: '36px'},
        lineHeight: '50px',
        color: "#000000",
    },
    faqHeadingText: {
        fontFamily: 'Poppins',
        fontWeight: '700',
        fontSize: {md: '40px', xs: '24px'},
        lineHeight: '60px'
    },
    descriptionText: {
        fontFamily: 'Poppins',
        fontSize: {md: '20px', xs: '14px'},
        lineHeight: {md: '30px', xs: '21px'},
    },
    descriptionLevel2Text: {
        fontFamily: 'Roboto',
        fontSize: {md: '16px', xs: '13px'},
        mt: '15px',
    },
    specialText: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: {md: '17px', xs: '15px'},
        marginY: '14px',
        color: theme.palette.secondary.main,
        textAlign: {md: 'left', xs: 'center'},
    },
    descriptionBox: {
        marginTop: '12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingX: '20px'
    },
    downloadAppsBox: {
        backgroundColor: theme.palette.primary.main,
        paddingX: {md: theme.gutterWidth, xs: '20px'},
        backgroundImage: `url(${BgPattern})`,
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: {md: '35px', xs: '15px'}
    },
    storeButtonsContainer: {
        marginTop: {md: '30px', xs: '15px'},
        display: {md: 'block', xs: 'flex'},
        flexDirection: {md: 'row', xs: 'column'}
    },
    storeButton: {
        marginRight: '15px',
        cursor: 'pointer'
    },
    storeButtonMobile: {
        margin: '5px 0px',
        cursor: 'pointer'
    },
    topButtons: {
        // backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '20px',
        height: {md: 'fit-content', xs: 'auto'},
        py: theme.spacing(3.25),
        px: {md: theme.spacing(0), xs: theme.spacing(3)},
        flex: 1,
    },
    topButtonsText: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        fontSize: {md: '24px', xs: '14px'},
        lineHeight: {md: '36px', xs: '18px'},
        textAlign: 'center',
        justifyContent: 'center',
        textTransform: 'initial'
    },
})

