import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { FAQs, Home, TermsOfUse } from './pages';
import { AppTheme } from './constants';
import { Layout } from './components';
import { CssBaseline } from '@mui/material';
import { PrivacyPolicy } from './pages';
import smoothScroll from 'smoothscroll-polyfill';

function App() {
  smoothScroll.polyfill();
  return (
    <ThemeProvider theme={AppTheme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        style={{ zIndex: 10000 }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <Routes>
          <Route element={<Layout />}>
            <Route exact path="/" element={<Home />} />
            {/*<Route exact path="/team" element={<Team/>}/>*/}
            {/*<Route exact path="/team-detail/:id" element={<TeamDetail/>}/>*/}
            <Route exact path="/faqs" element={<FAQs />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms-of-use" element={<TermsOfUse />} />
          </Route>
        </Routes>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
