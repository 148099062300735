export const PROVIDER_FAQS = [
    {
        question: 'How does it work?',
        answer: 'AyR 4 Asthma enables physicians to generate an asthma action plan by choosing the maintenance medications and the best peak flow reading for the patient. A back-end algorithm created by pulmonologists generates the asthma action plan. You can personalize the plan for your patient if required.'
    },
    {
        question: 'What are the components?',
        answer: 'AyR 4 Asthma has 4 components: <br/>' +
            'Asthma Action Plan: tailored for the patient by the physician <br/>' +
            'Symptom or peak flow-based quick access to relevant zone of treatment plan<br/>' +
            'Peak flow diary: Graphical representation of Peak flow recordings with symptoms. <br/>' +
            'Inhaler technique: Inhaler technique videos personalized to your patient. <br/>' +
            'Asthma Control Test:  Graphical representation of your patient’s asthma control in the clinic  <br/>' +
            'Frequently Asked Questions: To improves your patient’s knowledge of the disease. <br/>'
    },
    {
        question: 'Why is it important? ',
        answer: 'Research studies repeatedly report that physicians do not have the adequate know-how to confidently prescribe an asthma action plan. Even if we do, we do not have enough time in our clinics to generate an asthma action plan hence procrastinating for the next time. The complexities of various inhale medications and remembering their regulatory daily limits do not make it easy as well. Hence only  ..% of patients are only prescribed an asthma action plan. <br/>' +
            'Now you can ensure a patient has an easy access to the treatment plan when needed. <br/>' +
            'Help your patients avoid an exacerbation and a hospital admission.<br/>' +
            'All international societies including GINA endorse and emphasize that patients must be provided with a personalized asthma action plan for optimum asthma care.<br/>'
    },
    {
        question: 'Value for patients?',
        answer: 'Patient do not have to hunt for their paper-based treatment plan. <br/>' +
            'They can answer the questions on their symptoms and the app will guide them on which medicine to take. <br/>' +
            'Patients will become more educated about their medications. They will be come more adherent to taking their medicines as well as monitoring their asthma control <br/>' +
            'Each asthma action plan is tailored to a patient, hence this is a customer centric digital platform <br/>' +
            'The inhaler technique video ensure that patients have constant access to the techniques. <br/>'
    },
    {
        question: 'How do I get started?',
        answer: 'Download www.Ayra.com in any of these internet platforms – Chrome, firefox…<br/>' +
            'Register with your name, medical license number, hospital details.<br/>' +
            'Within 48 hours you will receive an email and message confirming your access to the platform <br/>'
    },
    {
        question: 'What are the components on the website?',
        answer: 'AyR 4 Asthma website will have two components <br/>' +
            '<strong>New patient</strong><br/><strong>Existing patient</strong> <br/>' +
            '<strong>New patient:</strong> <br/>' +
            '<strong>Treatment plan:</strong> <br/>' +
            'Enter the regular maintenance medications,reliever medication , best peak flow reading if available  from the drop-down tab <br/>' +
            'Generate asthma action plan <br/>' +
            'The traffic light system of asthma action plan will be generated automatically <br/>' +
            'Edit each zone to tailor to your patient if you need to.<br/>' +
            'Confirm the asthma action plan if you agree.<br/>' +
            'Enter other medications to complete the patient medication profile.<br/>' +
            'Perform asthma control test to monitor your patient’s asthma control .<br/>' +
            '<br/>' +
            '<strong>Existing patient</strong><br/>' +
            'Once you have onboarded patients, you can review and edit your patient details<br/>' +
            '1. The treatment plans <br/>' +
            '2. Peak flow diary <br/>' +
            '3. Asthma control test <br/>'
    },
    {
        question: 'How do I onboard patients?',
        answer: 'In the new patient section: click on + sign and enter the relevant patient details: <br/>' +
            'Name, MR number, mobile number <br/>' +
            'Patient will receive a text message notification to download the app from their app store/ google pay store <br/>'
    },
];
export const PATIENT_FAQS = [
    {
        question: 'Where can I find the app? ',
        answer: 'Download AyR 4 ASTHMA onto your phone or tablet through the Apple App store or the Google Play store. You can download the app from the text message you received from your physician '
    },
    {
        question: 'What device do you need? ',
        answer: 'AyR 4 ASTHMA works on all iOS and android devices. If you experience any issues on your device while using the app, please let us know by emailing us at.'
    },
    {
        question: 'Who can use AyR 4 ASTHMA?',
        answer: 'All patients diagnosed with bronchial asthma by their physician can use the app '
    },
    {
        question: 'What are the various components in this app?',
        answer: 'AyR4Asthma has 4 components: <br/>' +
            'Asthma Action Plan: tailored for the patient by the physician <br/>' +
            'Symptom or peak flow-based quick access to relevant zone of treatment plan<br/>' +
            'Peak flow diary: Graphical representation of Peak flow recordings with symptoms. <br/>' +
            'Inhaler technique: Inhaler technique videos personalized to your patient. <br/>' +
            'Frequently Asked Questions: To improves your patient’s knowledge of the disease. <br/>'
    },
    {
        question: 'How accurate is the treatment plan and information provided?',
        answer: 'The AyR 4 ASTHMA app is created by certified pulmonologists in accordance with latest international guidelines. The treatment plan is reviewed and generated by your physician and tailored to your need.'
    },
];
export const TEAM_MEMBERS = [
    {
        image: 'Dr-Merlin-Thomas.png',
        fullName: 'Dr Merlin Thomas',
        id: 'Dr-Merlin-Thomas',
        email: 'merlin-thomas@ayra.health',
        linkedIn: 'linkedIn.com/merlin-thomas',
        designation: 'Chief Executive Officer & Co-Founder',
        expertise: 'Pulmonologist and Assistant Professor of Clinical Medicine',
        bio: 'Dr Merlin is a pulmonologist with more than 10 years’ experience in pulmonary with expertise in' +
            ' asthma and airway diseases. She has been awarded several grants for research and quality improvement' +
            ' projects to improve health care systems for best patient care in Qatar. AYRA is the product of the ' +
            'excellence in care of asthma project.'
    },
    {
        image: 'Dr-Mansoor-Hameed.png',
        fullName: 'Dr Mansoor Hameed',
        id: 'Dr-Mansoor-Hameed',
        email: 'mansoor-hameed@ayra.health',
        linkedIn: 'linkedIn.com/mansoor-hameed',
        designation: 'Chief Science Officer & Co Founder',
        expertise: 'Pulmonologist and Assistant Professor of Clinical Medicine',
        bio: 'Dr Mansoor is the CSO of AYRA and a pulmonologist with expertise in Asthma and airway diseases. ' +
            'He trained in the UK and has over 19 years of experience. He is recognised as a regional and local ' +
            'asthma expert and has been working to improve outcomes for patients with airway diseases recognised ' +
            'by several awards and peer reviewed publications.'
    },
    {
        image: 'Rakesh-Mathew.png',
        fullName: 'Rakesh Mathew',
        id: 'Rakesh-Mathew',
        email: 'rakesh-mathew@ayra.health',
        linkedIn: 'linkedIn.com/rakesh-mathew',
        designation: 'Chief Technical Officer',
        expertise: 'MS, MBA, CPHIMS',
        bio: 'Rakesh Mathew is a seasoned executive with expertise in digital transformation, healthcare data ' +
            'aggregation, and interoperability. He has led multiple EMR modernization efforts and FHIR ' +
            'implementations, participating in various HL7 FHIR Accelerators and Workgroups. Previously, ' +
            'Mathew served as Chief Innovation Officer at HealthShare Exchange, overseeing its growth and ' +
            'adoption. He has extensive experience in the healthcare payer industry, working with Horizon BCBS and ' +
            'Verizon, and played a pivotal role in deploying EMR solutions for over 6,000 physicians.'
    }
];


export const COMMENTS = [
       {
           userType: 'Doctor',
           rating: 5,
           comment: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. ”',
           userAvatar: "avatar url",
           userName: 'Frank Stark',
           commentPlatform: 'Apple Store'
       },
       {
           userType: 'Patient',
           rating: 5,
           comment: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. ”',
           userAvatar: 'avatar url',
           userName: 'Rebecca',
           commentPlatform: 'Google Play Store'
       },
       {
           userType: 'Doctor',
           rating: 5,
           comment: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ”',
           userAvatar: 'avatar url',
           userName: 'Olivia Pippa',
           commentPlatform: 'Google Play Store'
       },
       {
           userType: 'Doctor',
           rating: 5,
           comment: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. ”',
           userAvatar: 'avatar url',
           userName: 'James',
           commentPlatform: 'Google Play Store'
       },
       {
           userType: 'Doctor',
           rating: 5,
           comment: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. ”',
           userAvatar: 'avatar url',
           userName: 'David',
           commentPlatform: 'Google Play Store'

       },
       {
           userType: 'Doctor',
           rating: 5,
           comment: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. ”',
           userAvatar: 'avatar url',
           userName: 'James',
           commentPlatform: 'Google Play Store'
       },
       {
           userType: 'Doctor',
           rating: 5,
           comment: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. ”',
           userAvatar: 'avatar url',
           userName: 'David',
           commentPlatform: 'Google Play Store'

       }
   ]
